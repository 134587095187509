import get from 'lodash/get';
import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import {
  actions as contentActions,
  ContentRecord,
  getKey,
} from '../slices/content';
import store, { useAppSelector } from '../store';
import useLocale from './useLocale';

export const usePluralize = (content: ContentRecord) =>
  useCallback(
    (key: string, count: number) => {
      const isPlural = count !== 1;
      const str = get(content, isPlural ? `${key}_plural` : key);

      if (!str) {
        return '';
      }

      return str.replace('{{count}}', count);
    },
    [content]
  );

/**
 * Hook to get localized content.
 */
const useLocalizedContent = (type: string) => {
  const dispatch = useDispatch();
  const locale = useLocale();

  const content = useAppSelector(
    ({ content }) =>
      content.data[getKey(content.locale, type)] ?? { __empty: true },
    shallowEqual
  );

  useEffect(() => {
    if (store.getState().content.loading[getKey(locale, type)] !== undefined) {
      return;
    }

    dispatch(contentActions.loadContent({ type }));
  }, [dispatch, locale, type]);

  return content;
};

export default useLocalizedContent;
