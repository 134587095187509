import { ComponentsProps } from '@material-ui/core/styles/props';

export default (): ComponentsProps => ({
  MuiButton: {
    disableElevation: true,
    variant: 'contained',
    children: '', // prevent an error when content is not yet loaded
  },
  MuiCardHeader: {
    titleTypographyProps: {
      color: 'textSecondary',
      variant: 'body1',
    },
    subheaderTypographyProps: {
      color: 'textSecondary',
      variant: 'caption',
    },
  },
  MuiCheckbox: {
    color: 'primary',
  },
  MuiInput: {
    disableUnderline: true,
    fullWidth: true,
  },
  MuiOutlinedInput: {
    margin: 'dense',
    fullWidth: true,
  },
});
