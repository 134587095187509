import { useEffect, useState } from 'react';
import PostsService from '../services/PostsService';
import useAuthState from './useAuthState';

export const useChallengeCompleted = (postId: string, subscribe: boolean) => {
  const { user } = useAuthState();
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    if (!subscribe || !user) {
      return;
    }

    const statusSubscription = PostsService.instance.isChallengeCompleted$(
      postId,
      (_complete) => setComplete(_complete)
    );

    return () => {
      statusSubscription?.();
    };
  }, [postId, subscribe, user]);
  return complete;
};

export default useChallengeCompleted;