import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import BAuthModal from './BAuthModal/BAuthModal';
import BDonate from './BDonate/BDonate';
import BFooter from './BFooter';
import BNewPostDialog from './createPost/BNewPostDialog';
import BNavBar from './nav/BNavBar';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: `calc(100vh - ${theme.spacing(16)}px)`,
  },
  top: {
    height: theme.spacing(6),
  },
}));

const PageWithLoginAndSignup: React.FC = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  const hideNavRegex = /^\/(landing\/[a-zA-Z0-9-]*)?$/;
  if (hideNavRegex.test(location.pathname)) {
    return <>{children}</>;
  }

  return (
    <>
      <BNavBar />
      <div className={classes.top} />
      <Box className={classes.content} component="main">
        {children}
      </Box>
      <BFooter />
      <BNewPostDialog />
      <BDonate />
      <BAuthModal />
    </>
  );
};

export default PageWithLoginAndSignup;
