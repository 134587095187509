import { Typography, TypographyProps } from '@material-ui/core';
import Linkify, { LinkifyProps } from 'linkifyjs/react';
import React, { memo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '../../../BRouter';

const linkify = require('linkifyjs') as any;
const hashtag = require('linkifyjs/plugins/hashtag') as any;
hashtag(linkify);

interface Props extends LinkifyProps {
  TypographyProps?: TypographyProps;
}

const BLinkify: React.FC<Props> = memo(({ TypographyProps, ...props }) => {
  const options = props.options;
  const attributes = options?.attributes;
  const outerClick = attributes?.onClick;
  const history = useHistory();
  const { children } = props;

  let _children = children;

  if (children && typeof children === 'string') {
    _children = children.split('\n').map((text) => (
      <>
        {text}
        <br />
      </>
    ));
  }

  const hashtaggedProps = {
    ...props,
    children: _children,
    options: {
      ...options,
      attributes: {
        ...attributes,
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          outerClick && outerClick(e);
          const anchor = e.target as HTMLAnchorElement;
          const origin = window.location.origin;
          if (anchor.href.startsWith(origin)) {
            e.preventDefault();
            history.push(anchor.href.split(origin)[1]);
          }
        },
      },
      formatHref: (href: string, type: string) => {
        if (type === 'hashtag') {
          href =
            generatePath(ROUTES.getInspired, {}) +
            '?q=' +
            encodeURIComponent(href);
        }
        return href;
      },
    },
  } as any;

  return (
    <Typography component="span" {...TypographyProps}>
      <Linkify {...hashtaggedProps} />
    </Typography>
  );
});

export default BLinkify;
