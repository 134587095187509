import { Box, Container, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import usePostData from '../../hooks/usePostData';
import { ROUTES } from '../BRouter';
import BPostCard from '../layout/posts/BPostCard/BPostCard';
import BPostCardListItem from '../layout/posts/BPostCard/BPostCardListItem';
import BOnscreenToggle from '../utils/BOnscreenToggle';
import BPostScroller from '../layout/posts/BPostScroller';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const INCREMENT = 5;

const BPostWithCommentsPage = () => {
  const content = useLocalizedContent('my_posts');
  const classes = useStyles();
  const history = useHistory();
  const { id: postId } = useParams<{ id: string }>();
  const [limit, setLimit] = useState(INCREMENT);
  const opts = useMemo(() => ({ descendants: { limit } }), [limit]);
  const { post, comments, descendants } = usePostData(postId, true, opts);

  const incrementLimit = useCallback(() => {
    setLimit((_limit) =>
      descendants.length < _limit ? _limit : _limit + INCREMENT
    );
  }, [setLimit, descendants.length]);

  useEffect(() => {
    if (post === null) {
      history.push(ROUTES.getInspired);
    }
  }, [post, history]);

  return (
    <>
      <Container className={classes.container}>
        {post && comments ? (
          <BPostCard key={post.id} post={post} comments={comments} standalone />
        ) : (
          <div />
        )}
        {!!descendants.length && post?.isChallenge && !post.rootPost && (
          <Box marginTop={5}>
            <Typography align="center" variant="h3">
              {content.challenge_accepted_by}
            </Typography>
          </Box>
        )}
        {!!descendants.length &&
          descendants.map((post) => (
            <BPostCardListItem key={post.id} post={post} disableEmbed />
          ))}

        <Divider style={{ marginTop: 50, marginBottom: 50 }} />

        <Typography variant="h3">{content.more_posts}</Typography>

        <BPostScroller showMap hideSidebar />
      </Container>
      <BOnscreenToggle onScreen={incrementLimit} />
    </>
  );
};

export default BPostWithCommentsPage;
