import React, { useEffect, useRef, useState } from 'react';
import { Post, Id, LatLngBounds, Geoloc } from 'bee';
import { Box, Card, Divider, Typography } from '@material-ui/core';
import {
  GoogleMap,
  InfoWindow,
  OverlayView,
  withGoogleMap,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import useProfile from '../../../hooks/useProfile';
import BProfileLink from '../../utils/BProfileLink';
import BUserAvatar from '../../utils/BUserAvatar';
import MediaService from '../../../services/MediaService';

interface BMapProps {
  posts: (Post & Id)[];
}

interface Map {
  bounds?: LatLngBounds;
  coords?: Geoloc;
  posts: (Post & Id)[];
}

interface CustomMarkerProps {
  post: Post & Id;
  onClick: () => void;
}

function CustomMarker({ post, onClick }: CustomMarkerProps) {
  const userId = post.userId;
  const profilePhoto = MediaService.instance.getProfileImageUrl(userId);
  const defaultPhoto = '/media/bee-pin.png';

  return (
    <div
      onClick={onClick}
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -100%)', // Center the marker
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 50,
          height: 50,
          borderRadius: '50%', // Circular shape
          overflow: 'hidden',
          border: '2px solid black',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={profilePhoto || defaultPhoto}
          alt="User"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          onError={(e) => {
            e.currentTarget.src = defaultPhoto;
          }}
        />
      </div>
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '10px solid transparent', // Left side of the triangle
          borderRight: '10px solid transparent', // Right side of the triangle
          borderTop: '10px solid black', // Bottom side of the triangle (appears as the top due to the border placement)
          marginTop: '-2px', // Adjust to connect precisely with the circle
        }}
      />
    </div>
  );
}

const Map = compose<Map, Map>(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `60vh`, minHeight: '50vh' }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(({ coords, bounds, posts }) => {
  const defaultCenter = { lat: 45.2665, lng: -72.148 };
  const map = useRef<any>();
  const [selectedPost, setSelectedPost] = useState<typeof posts[number] | null>(
    null
  );

  const selectedProfile = useProfile(selectedPost?.userId);

  useEffect(() => {
    if (bounds) {
      map?.current?.fitBounds(bounds);
    }
  }, [bounds]);

  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={defaultCenter}
      // center={coords ? coords : defaultCenter}
      defaultOptions={{
        zoomControl: true,
        panControl: true,
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeControl: false,
        draggable: true,
        scrollwheel: true,
        disableDefaultUI: true,
        disableDoubleClickZoom: false,
        draggableCursor: '',
      }}
      ref={map}
    >
      {posts.map((post, index) => {
        const lat = post._geoloc?.lat! + Math.random() / 500;
        const lng = post._geoloc?.lng! + Math.random() / 500;

        const latLngBroken =
          typeof lat !== 'number' ||
          typeof lng !== 'number' ||
          isNaN(lat) ||
          isNaN(lng);

        // Check if lat and lng are valid numbers
        if (latLngBroken) {
          console.warn(`Invalid geolocation for post ID ${post.id}`);
          return null; // Skip rendering this marker
        }

        return (
          <OverlayView
            key={index}
            position={{ lat, lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <CustomMarker post={post} onClick={() => setSelectedPost(post)} />
          </OverlayView>
        );
      })}

      {selectedPost && (
        <InfoWindow
          position={{
            lat: selectedPost._geoloc?.lat!,
            lng: selectedPost._geoloc?.lng!,
          }}
          onCloseClick={() => setSelectedPost(null)}
        >
          <div>
            <Typography>{selectedPost.description}</Typography>
            <Typography variant="body2">
              {new Date(selectedPost.postedTimestamp).toLocaleString()}
            </Typography>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <BProfileLink userId={selectedPost.userId}>
              <Box
                flex
                flexDirection="row"
                display="flex"
                alignItems="center"
                style={{ gap: 8 }}
              >
                <BUserAvatar userId={selectedPost.userId} />
                <div>{selectedProfile?.username}</div>
              </Box>
            </BProfileLink>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
});

export function BMap({ posts }: BMapProps) {
  const gmapsScript = document.getElementById(
    'google-maps-script'
  ) as HTMLScriptElement;
  const [scriptLoaded, setScriptLoaded] = useState(
    gmapsScript.getAttribute('data-loaded') === 'true'
  );
  console.log('@@ scriptLoaded', scriptLoaded);

  useEffect(() => {
    if (!scriptLoaded) {
      gmapsScript.addEventListener('load', () => {
        setScriptLoaded(true);
      });
    }
  }, [gmapsScript, gmapsScript.onload, scriptLoaded]);

  return (
    <Box mt={2}>
      <Card>{scriptLoaded && <Map posts={posts} />}</Card>
    </Box>
  );
}
