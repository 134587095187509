import { Id, Post } from 'bee';
import React, { memo } from 'react';
import BImage from '../BImage';
import BPostVideoPlayer from '../BPostVideoPlayer';

interface Props {
  post: Post & Id;
}

const PostMedia: React.FC<Props> = memo(({ post }) => {
  if (post.mediaType?.startsWith('image/') && post.mediaUrl) {
    return <BImage src={post.mediaUrl!} alt={post.description.substr(0, 30)} />;
  } else if (post.mediaType?.startsWith('video/')) {
    return <BPostVideoPlayer post={post} />;
  }

  return null;
});

export default PostMedia;
