import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

interface Props {
  className?: string;
  src?: string;
  poster?: string;
  options?: videojs.PlayerOptions;
}

const useStyles = makeStyles({
  root: {
    '& .vjs-big-play-button': {
      top: 'calc(50% - 0.81666em)',
      left: 'calc(50% - 1.5em)',
    },
  },
});

const BVideoPlayer: React.FC<Props> = ({ className, src, poster, options }) => {
  const classes = useStyles();
  const video = useRef<HTMLVideoElement | null>(null);
  const player = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    const el = video.current;

    if (src && el) {
      player.current = videojs(
        el,
        {
          fluid: true,
          ...options,
        },
        function (this: VideoJsPlayer) {
          this.on('play', () => {
            videojs.getAllPlayers().forEach((p) => {
              if (p !== this) {
                p.pause();
              }
            });
          });
        }
      );
    }

    return () => {
      try {
        player.current?.dispose();
      } catch (e) {
        console.log('@@error', e);
      }
    };
  }, [video, src, options]);

  if (!src) {
    return null;
  }

  return (
    <div>
      <div data-vjs-player>
        <video
          ref={video}
          className={clsx('video-js', classes.root, className)}
          poster={poster}
          controls
        >
          <source src={src} type="application/x-mpegurl" />
        </video>
      </div>
    </div>
  );
};

export default BVideoPlayer;
