import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AuthService, { UserObject } from '../services/AuthService';
import { bAnalytics, Events } from '../utils/analytics';
import { actions as rootActions } from './root';

type AuthModal = 'login' | 'signup';

interface State {
  authModal?: AuthModal;
  user: UserObject | null;
  authInitialized: boolean;
}

const INITIAL_STATE: State = {
  user: null,
  authInitialized: false,
};

const createEmailAccountSubmit = createAsyncThunk<
  void,
  { email: string; password: string }
>(
  'auth/createEmailAccountSubmit',
  async ({ email, password }, { dispatch }) => {
    try {
      const userCredentials = await AuthService.instance.registerWithEmail(
        email,
        password
      );
      await AuthService.instance.sendVerificationEmail(userCredentials.user);
      bAnalytics.track(Events.Registered);
    }
    catch (e) {
      console.error('@@ error: ', e);
      dispatch(rootActions.showInfo({
        text: (e as any).message,
        severity: 'error',
      }) as any);
    }
    dispatch(slice.actions.closeModal());
  }
);

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    showSignupModal: (state) => {
      state.authModal = 'signup';
    },
    showLoginModal: (state) => {
      state.authModal = 'login';
    },
    toggleAuthModal: (state) => {
      state.authModal = state.authModal === 'login' ? 'signup' : 'login';
    },
    closeModal: (state) => {
      delete state.authModal;
    },
    setUser: (state, action: PayloadAction<UserObject | null>) => {
      state.user = action.payload;
      state.authInitialized = true;
    },
    updateUser: (state, action: PayloadAction<Partial<UserObject>>) => {
      state.user = {
        ...state.user!,
        ...action.payload,
      };
    },
  },
});

export const actions = {
  ...slice.actions,
  createEmailAccountSubmit,
};

export default slice.reducer;
