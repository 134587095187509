import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check, ErrorOutlined, Replay } from '@material-ui/icons';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useState } from 'react';
import isEmail from 'validator/es/lib/isEmail';
import useCause from '../../../hooks/useCause';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useUser from '../../../hooks/useUser';
import { actions as donationActions } from '../../../slices/donations';
import { useAppDispatch, useAppSelector } from '../../../store';
import { bAnalytics, Events } from '../../../utils/analytics';
import BTextField from '../../inputs/BTextField';
import BDialog from '../BDialog';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    ...theme.overrides?.MuiTypography?.h3,
    padding: 0,
    textAlign: 'center',
  },
  amountBox: {
    cursor: 'pointer',
  },
  amountBoxSelected: {
    border: `5px solid ${theme.palette.primary.main}`,
  },
  label: {
    display: 'block',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    border: '1px solid rgba(0,0,0,0.23)',
    padding: '6px 8px',
    borderRadius: '16px',
  },
}));

const useClose = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(donationActions.showModal(false));
  }, [dispatch]);
};

const useHandleSubmit = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  return useCallback(
    async (event) => {
      event.preventDefault();
      dispatch(
        donationActions.completeDonation({
          stripe,
          elements,
        })
      );
    },
    [dispatch, elements, stripe]
  );
};

const useUpdatePaymentIntent = (amount: number, email: string) => {
  const {
    paymentIntent,
    showDonationModal,
    donationCause,
    donationChallenge,
    donationComplete,
  } = useAppSelector((state) => state.donations);
  const { id: intentId } = paymentIntent || {};

  const dispatch = useAppDispatch();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        showDonationModal &&
        donationCause &&
        email &&
        isEmail(email) &&
        amount >= 100 &&
        !donationComplete
      ) {
        dispatch(
          donationActions.createPaymentIntent({
            ...(intentId ? { intentId } : {}),
            causeId: donationCause,
            challengeId: donationChallenge,
            amount,
            email,
          })
        );
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    intentId,
    dispatch,
    showDonationModal,
    donationCause,
    amount,
    email,
    donationChallenge,
    donationComplete,
  ]);

  return paymentIntent;
};

interface AmountButtonProps {
  amount: number;
  customAmount?: number;
  buttonAmount?: number;
  changeHandler: (value: number) => void;
}

const MIN_DONATION = 500;

const AmountButton = memo(
  ({ amount, buttonAmount, changeHandler }: AmountButtonProps) => {
    const classes = useStyles();
    const [customAmount, setCustomAmount] = useState<number | undefined>();

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (customAmount !== undefined && customAmount < MIN_DONATION) {
          setCustomAmount(MIN_DONATION);
          changeHandler(MIN_DONATION);
        }
      }, 500);

      return () => clearTimeout(timeout);
    }, [customAmount, setCustomAmount, changeHandler]);

    return (
      <Paper
        className={clsx(
          classes.amountBox,
          buttonAmount === amount && classes.amountBoxSelected,
          amount === customAmount && classes.amountBoxSelected
        )}
        onClick={() =>
          buttonAmount
            ? changeHandler(buttonAmount)
            : customAmount && changeHandler(customAmount)
        }
      >
        <Box px={1} py={5}>
          {buttonAmount && (
            <Typography variant="h3" align="center">
              ${buttonAmount / 100}
            </Typography>
          )}
          {!buttonAmount && (
            <Input
              classes={{
                input: classes.input,
              }}
              placeholder="75"
              type="number"
              value={customAmount ? customAmount / 100 : undefined}
              onChange={(e) => {
                let value = parseInt(e.target.value);

                if (value === undefined) {
                  setCustomAmount(value);
                  changeHandler(MIN_DONATION);
                }

                value = value < 0 ? 0 : value;

                value = value * 100;
                setCustomAmount(value);
                changeHandler(value);
              }}
              startAdornment={
                <InputAdornment
                  position="start"
                  classes={{
                    positionStart: classes.input,
                  }}
                  disableTypography
                  className={classes.input}
                >
                  $
                </InputAdornment>
              }
            />
          )}
        </Box>
      </Paper>
    );
  }
);

export const BDonate = memo(() => {
  const content = useLocalizedContent('donate');
  const {
    showDonationModal,
    donationCause,
    donationComplete,
    donationError,
    processing,
    creatingIntent,
  } = useAppSelector((state) => state.donations);
  const user = useUser();
  const dispatch = useAppDispatch();

  const classes = useStyles();

  const [email, setEmail] = useState(user?.email || '');
  const [amount, setAmount] = useState(500);
  const [cardComplete, setCardComplete] = useState(false);

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email);
    }
  }, [user]);

  const onCardChange = useCallback(
    (evt) => {
      setCardComplete(evt.complete);
    },
    [setCardComplete]
  );

  const cause = useCause(donationCause || '');
  const close = useClose();

  useUpdatePaymentIntent(amount, email);
  const handleSubmit = useHandleSubmit();

  const handleAmountButtonClick = useCallback((value: number) => {
    bAnalytics.track(Events.SetDonationAmount, { value });
    setAmount(value);
  }, []);

  return (
    <BDialog open={showDonationModal} onClose={close} title={content.title}>
      {!donationError && !donationComplete && !processing && (
        <>
          <Typography variant={'h3'}>{cause?.name}</Typography>
          <Typography>{cause?.description}</Typography>
          <Box py={1} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <AmountButton
                changeHandler={handleAmountButtonClick}
                amount={amount}
                buttonAmount={500}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AmountButton
                changeHandler={handleAmountButtonClick}
                amount={amount}
                buttonAmount={2000}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AmountButton
                changeHandler={handleAmountButtonClick}
                amount={amount}
              />
            </Grid>
          </Grid>
          <Box py={1} />
          <form onSubmit={handleSubmit}>
            <BTextField
              label={content.email}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Box py={1} />
            <InputLabel disableAnimation>
              <span className={classes.label}>
                {content.credit_card_details}
              </span>
              <div className={classes.card}>
                <CardElement onChange={onCardChange} />
              </div>
            </InputLabel>
            <Box py={1} />
            <Typography>
              <small>{content.disclaimer}</small>
            </Typography>
            <Box py={1} />
            <Grid container justify="center">
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  size="large"
                  disabled={
                    !(
                      !creatingIntent &&
                      email &&
                      isEmail(email) &&
                      cardComplete &&
                      amount &&
                      amount >= 1
                    )
                  }
                >
                  {content.cta}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      {!!donationError && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <ErrorOutlined color="secondary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography color="secondary" align="center">
              {donationError}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => dispatch(donationActions.setError(undefined))}
            >
              <Replay />
            </Button>
          </Grid>
        </Grid>
      )}
      {!!processing && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      )}
      {!!donationComplete && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Check color="primary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography align="center" variant="h3">
              {content.thank_you}
            </Typography>
            <Typography align="center">
              {content.thank_you_secondary}
            </Typography>
          </Grid>
        </Grid>
      )}
    </BDialog>
  );
});

export default BDonate;
