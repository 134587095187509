import { ProfileMeta } from 'bee';
import FirestoreService from './FirestoreService';


export default class ProfilesMetaService extends FirestoreService<ProfileMeta> {
  static instance = new ProfilesMetaService();

  constructor() {
    super('profilesMeta');
  }

  update(id: string, data: ProfileMeta) {
    return this.doc(id).set({ id: '', ...data }, { merge: true });
  }
}
