import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import { AppState, AppThunk } from '../store';
import { bAnalytics, Events } from '../utils/analytics';
import { actions as authActions } from './auth';

const logout = createAsyncThunk('login/logout', () => {
  bAnalytics.track(Events.LoggedOut);
  const promise = AuthService.instance.userLogout();
  setTimeout(() => window.location.reload(), 50);
  return promise;
});

const login = ({
  username,
  password,
}: {
  username: string;
  password: string;
}): AppThunk => async (dispatch) => {
  await AuthService.instance.userLogin(username, password);
  dispatch(authActions.closeModal());
  bAnalytics.track(Events.LoggedIn);
};

const signinWithGoogle = (): AppThunk => async (dispatch) => {
  await AuthService.instance.googleSignin();
  dispatch(authActions.closeModal());
  bAnalytics.track(Events.LoggedIn);
};

const signinWithFacebook = (): AppThunk => async (dispatch) => {
  await AuthService.instance.facebookSignin();
  dispatch(authActions.closeModal());
  bAnalytics.track(Events.LoggedIn);
};

const forgotPasswordSendResetEmail = ({
  email,
}: {
  email: string;
}): AppThunk => async (dispatch, getState) => {
  const locale = (getState() as AppState).content.locale;

  await AuthService.instance.sendPasswordResetEmail(email, locale);
  bAnalytics.track(Events.PasswordReset);
};

export const actions = {
  login,
  logout,
  signinWithFacebook,
  signinWithGoogle,
  forgotPasswordSendResetEmail,
};
