import { CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, Post } from 'bee';
import React, { CSSProperties, memo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import useProfile from '../../../../hooks/useProfile';
import { ROUTES } from '../../../BRouter';
import BUserAvatar from '../../../utils/BUserAvatar';
import BLinkify from './BLinkify';

export interface BPostDescendantProps {
  post: Post & Id;
}

const useStyles = makeStyles((theme) => ({
  post: {
    backgroundColor: theme.palette.brand.main,
    height: 200,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    overflow: 'hidden',
    position: 'relative',
  },
  userAvatar: {
    border: '2px solid white',
  },
  header: {
    padding: 0,
    zIndex: 10,
    position: 'relative',
  },
  headerTitle: {
    color: '#FFFFFF !important',
    textDecoration: 'none !important',
  },
  wrapperLink: {
    textDecoration: 'none !important',
  },
  gradient: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      'linear-gradient(0deg, rgba(20,20,20,0) 50%, rgba(20,20,20,0.304359243697479) 80%, rgba(20,20,20,0.46122198879551823) 100%)',
    filter:
      'progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#141414",GradientType=1)',
  },
  content: {
    position: 'relative',
    color: 'white',
    height: '100%',
    paddingBottom: 30,
    textShadow: '0 2px 10px rgba(0,0,0,0.8)',
  },
  grow: {
    flexShrink: 0,
    flexGrow: 1,
  },
}));

export const BPostDescendant = memo(({ post }: BPostDescendantProps) => {
  const profile = useProfile(post.userId);
  const classes = useStyles();
  let { mediaUrl } = post;
  if (mediaUrl?.startsWith('mux://')) {
    mediaUrl = `https://image.mux.com/${mediaUrl.replace(
      'mux://',
      ''
    )}/thumbnail.png?fit_mode=pad`;
  }
  const style: CSSProperties = !!mediaUrl
    ? {
        backgroundImage: `url(${mediaUrl})`,
        backgroundSize: 'cover',
      }
    : {};

  return (
    <Link
      to={generatePath(ROUTES.singlePost, {
        id: post.id,
      })}
      className={classes.wrapperLink}
    >
      <Grid className={classes.post} container direction="column" style={style}>
        <div className={classes.gradient} />
        <Grid item>
          <CardHeader
            className={classes.header}
            avatar={
              <BUserAvatar
                userId={post.userId}
                className={classes.userAvatar}
              />
            }
            title={
              <span className={classes.headerTitle}>{profile?.username}</span>
            }
          />
        </Grid>
        <Grid item className={classes.grow}>
          <Grid
            container
            className={classes.content}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item>
              {post.description && (
                <BLinkify>
                  <Typography align="center">
                    {post.description.slice(0, 50)}
                  </Typography>
                </BLinkify>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
});

export default BPostDescendant;
