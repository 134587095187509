import { AppPreferences, Id, Profile } from 'bee';
import FirestoreService, { converterWithId } from './FirestoreService';


export default class ProfilesService extends FirestoreService<Profile> {
  static instance = new ProfilesService();

  constructor() {
    super('profiles');
  }

  firstProfiles$(
    limit: number = 20,
    callback: (saved: (Profile & Id)[]) => void,
    filter?: string,
  ) {
    const query = this.collection().orderBy('createdAt', 'asc');

    if (filter) {
      query.where('tags', 'array-contains', filter);
    }

    query.limit(limit).withConverter(converterWithId<Profile>())
      .onSnapshot(sn => {
        callback(sn.docs.map(doc => {
          return doc.data();
        }));
      });
  }

  update(id: string, data: Profile) {
    return this.doc(id).set({ id: '', ...data }, { merge: true });
  }

  appPreferencesDoc(id: string) {
    return this.doc(id)
      .collection('preferences')
      .doc('app')
      .withConverter(converterWithId<AppPreferences>());
  }

  setNotificationsRead(id: string) {
    return this.appPreferencesDoc(id).set(
      {
        notificationsChecked: Date.now(),
      } as AppPreferences & Id,
      {
        merge: true,
      }
    );
  }

  setLocale(id: string, locale: string) {
    return this.doc(id).set(
      {
        locale,
      } as Profile & Id,
      {
        merge: true,
      }
    );
  }

  featuredProfiles() {
    return new Promise<(Profile & Id)[]>((resolve, reject) => {
      this.collection()
        .where('featured', '==', true)
        .limit(10)
        .withConverter(converterWithId<Profile>())
        .get()
        .then(sn => {
          resolve(sn.docs.map(doc => doc.data()));
        })
        .catch(reject);
    });
  }

}
