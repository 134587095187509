import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

export default (theme: Theme): Overrides => {
  const sharedTypographyStyles = {
    color: theme.palette.brand.dark,
    fontFamily: theme.typography.fontFamilyTitle,
  };

  return {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
      },
    },
    MuiAvatar: {
      root: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    },
    MuiButton: {
      root: {
        fontFamily: theme.typography.fontFamilyTitle,
        fontWeight: 700,
        padding: theme.spacing(1, 2.5),
        '&:focus': {
          outline: 'none',
        },
      },
      contained: {
        color: theme.palette.brand.dark,
        backgroundColor: theme.palette.background96,
      },
      containedPrimary: {
        color: theme.palette.primary.contrastText,
        background: theme.gradients.primary,
        '&:hover': {
          opacity: 1 - theme.palette.action.hoverOpacity,
        },
      },
      containedSecondary: {
        color: theme.palette.error.contrastText,
        background: theme.gradients.error,
        '&:hover': {
          opacity: 1 - theme.palette.action.hoverOpacity,
        },
      },
      outlined: {
        color: theme.palette.brand.dark,
        borderRadius: 0,
        border: 0,
      },
      outlinedPrimary: {
        border: 0,
        color: theme.palette.primary.contrastText,
        background: theme.gradients.primary,
        '&:hover': {
          border: 0,
          color: theme.palette.primary.contrastText,
          opacity: 1 - theme.palette.action.hoverOpacity,
        },
      },
      text: {
        fontFamily: theme.typography.fontFamily,
      },
    },
    MuiCard: {
      root: {
        border: `1px solid ${theme.palette.divider96}`,
      },
    },
    MuiCardHeader: {
      root: {
        padding: theme.spacing(1, 1.5),
        '& a': {
          color: theme.palette.text.secondary,
          textDecoration: 'none',
        },
      },
      avatar: {
        marginRight: theme.spacing(1.5),
      },
      action: {
        marginTop: 0,
      },
      title: {
        lineHeight: 1.2,
      },
      subheader: {
        lineHeight: 1.4,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
    },
    MuiIconButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamilyTitle,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenu: {
      paper: {
        border: `1px solid ${theme.palette.divider}`,
        borderBottom: 'none',
        borderRadius: 0,
      },
    },
    MuiMenuItem: {
      root: {
        minWidth: 220,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&.brand': {
          color: theme.palette.brand.contrastText,
          backgroundImage: theme.gradients.brand,
          '&:focus': {
            color: 'inherit',
            backgroundImage: 'inherit',
          },
        },
        '&:hover, &.brand:hover': {
          color: theme.palette.text.primary,
          backgroundImage: theme.gradients.primary,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: theme.palette.primary.light,
        },
      },
      marginDense: {
        borderRadius: theme.spacing(2),
      },
      inputMarginDense: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
      },
    },
    MuiTypography: {
      h1: {
        fontWeight: 700,
        ...sharedTypographyStyles,
      },
      h2: {
        fontWeight: 700,
        ...sharedTypographyStyles,
      },
      h3: {
        fontWeight: 700,
        fontSize: 25,
        ...sharedTypographyStyles,
      },
      h4: sharedTypographyStyles,
      h5: sharedTypographyStyles,
      h6: sharedTypographyStyles,
    },
  };
};
