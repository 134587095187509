import React from 'react';
import { Box, Button, Card, Typography } from '@material-ui/core';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import BRichText from '../../utils/BRichText';
import theme from '../../../theme';

export function BMapPromo() {
  const content = useLocalizedContent('get_inspired');
  return (
    <Card style={{ marginTop: theme.spacing(2) }}>
      {content.sidebar_promo_image && (
        <img
          src={content.sidebar_promo_image.url}
          alt={content.sidebar_promo_image.alt}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      )}
      <Box p={2}>
        {content.sidebar_promo_title && (
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {content.sidebar_promo_title}
          </Typography>
        )}
        {content.sidebar_promo_content && (
          <BRichText render={content.sidebar_promo_content}></BRichText>
        )}
        {content.sidebar_promo_cta_text && content.sidebar_promo_cta_link && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem' }}
            href={content.sidebar_promo_cta_link}
          >
            {content.sidebar_promo_cta_text}
          </Button>
        )}
      </Box>
    </Card>
  );
}
