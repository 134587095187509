import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import ads from './slices/ads';
import auth from './slices/auth';
import content from './slices/content';
import notifications from './slices/notifications';
import posts from './slices/posts';
import profiles from './slices/profiles';
import root from './slices/root';
import donations from './slices/donations';

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<T = void> = ThunkAction<T, AppState, undefined, Action>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export type AppThunkApiConfig = {
  state: AppState;
  dispatch: typeof store.dispatch;
};

const reducer = combineReducers({
  ads,
  auth,
  content,
  notifications,
  posts,
  profiles,
  root,
  donations,
});

const store = configureStore({
  reducer,
});

export default store;
