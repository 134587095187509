import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../BRouter';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const BFooter: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const content = useLocalizedContent('footer');

  return (
    <Box textAlign="center" component="footer" paddingY={2} {...props}>
      <Typography>
        {content.copyright}
        <br />
        <Link to={ROUTES.termsAndConditions}>{content.tc_link_text}</Link>
      </Typography>
    </Box>
  );
};

export default BFooter;
