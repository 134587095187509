import { Box, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import useSWR from 'swr';
import ProfilesService from '../../../../services/ProfilesService';
import BMiniProfile from '../../../utils/BMiniProfile';
import useLocalizedContent from '../../../../hooks/useLocalizedContent';

export function BFeaturedProfiles() {
  const content = useLocalizedContent('get_inspired');
  const fetcher = useCallback(async () => {
    return ProfilesService.instance.featuredProfiles();
  }, []);
  const { data } = useSWR('BFeaturedProfiles', fetcher);

  if (!data) {
    return null;
  }
  return (
    <Box my={2}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {content.featured_title}
      </Typography>
      <Box mt={2} />
      <Grid container spacing={2}>
        {data.map((p) => (
          <Grid item key={p.id} xs={12} sm={6}>
            <BMiniProfile profile={p} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
