import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, PostComment } from 'bee';
import clsx from 'clsx';
import React, { memo, useCallback, useState } from 'react';
import useLocalizedContent from '../../../../hooks/useLocalizedContent';
import Comment from './Comment';

interface Props {
  className?: string;
  comments: (PostComment & Id)[];
  expandByDefault?: boolean;
}

const DEFAULT_EXPANDED = 3;
const PAGE_SIZE = 5;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  listItem: {
    '& + li': {
      marginTop: theme.spacing(1),
    },
  },
  button: {
    marginTop: theme.spacing(-0.5),
  },
}));

const CommentsList: React.FC<Props> = memo(
  ({ className, comments, expandByDefault }) => {
    const classes = useStyles();
    const content = useLocalizedContent('my_posts');
    const [limit, setLimit] = useState(
      expandByDefault ? DEFAULT_EXPANDED + PAGE_SIZE : DEFAULT_EXPANDED
    );

    const showMore = useCallback(() => {
      setLimit((l) => {
        const newLimit = l + PAGE_SIZE;

        if (newLimit > comments.length) {
          return Infinity;
        }

        return newLimit;
      });
    }, [comments.length]);

    const hasMore = limit < comments.length;

    return (
      <ul className={clsx(classes.root, className)}>
        {hasMore && (
          <Button
            className={classes.button}
            variant="text"
            size="small"
            onClick={showMore}
            fullWidth
          >
            {content.show_more_comments}
          </Button>
        )}
        {comments.slice(-limit).map((comment) => (
          <Comment
            key={comment.id}
            className={classes.listItem}
            comment={comment}
          />
        ))}
      </ul>
    );
  }
);

export default CommentsList;
