import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { Ad, Id } from 'bee';
import AdsService from '../services/AdsService';

const getAds = createAsyncThunk('ads/getAds', () => AdsService.instance.list());

interface State {
  ads?: (Ad & Id)[];
  adsLoadingError?: SerializedError;
}

const INITIAL_STATE: State = {};

const slice = createSlice({
  name: 'ads',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAds.fulfilled, (state, action) => {
      state.ads = action.payload;
      delete state.adsLoadingError;
    });

    builder.addCase(getAds.rejected, (state, action) => {
      delete state.ads;
      state.adsLoadingError = action.error;
    });
  },
});

export const actions = {
  ...slice.actions,
  getAds,
};

export default slice.reducer;
