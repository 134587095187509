import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    '& img': {
      display: 'block',
      margin: theme.spacing(0, 2, 0, 1.5),
      [theme.breakpoints.down('xs')]: {
        height: 40,
        width: 'auto',
        marginRight: 0,
      },
    },
  },
}));

const BLogo = memo(() => {
  const classes = useStyles();

  const content = useLocalizedContent('nav');
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  if (content.__empty) {
    return null;
  }

  const logoUrl = isMobile ? content.small_logo.url : content.logo.url;

  return (
    <div className={classes.root}>
      <img
        alt="Bee Logo"
        width={80}
        srcSet={`${logoUrl}&w=80 1x, ${logoUrl}&w=160 2x, ${logoUrl}&w=240 3x`}
        src={`${logoUrl}&w=80`}
      />
    </div>
  );
});

export default BLogo;
