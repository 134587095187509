import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardElement } from '@stripe/react-stripe-js';
import Stripe from '@stripe/stripe-js';
import { CFCreateIntentData } from 'bee';
import firebase from 'firebase';
import { AppThunkApiConfig } from '../store';
import { bAnalytics, Events } from '../utils/analytics';
import { actions as rootActions } from './root';

interface State {
  showDonationModal: boolean;
  donationCause?: string;
  donationChallenge?: string;
  paymentIntent?: Stripe.PaymentIntent;
  donationComplete?: boolean;
  donationError?: string;
  processing: boolean;
  creatingIntent: boolean;
}

const INITIAL_STATE: State = {
  showDonationModal: false,
  donationComplete: false,
  processing: false,
  creatingIntent: false,
};

const createPaymentIntent = createAsyncThunk<
  Stripe.PaymentIntent,
  CFCreateIntentData,
  AppThunkApiConfig>(
  'donations/createPaymentIntent',
  async (data: CFCreateIntentData, thunkAPI) => {
    thunkAPI.dispatch(slice.actions.setCreatingIntent(true));
    const onCreatePaymentIntent = firebase.functions().httpsCallable('onCreatePaymentIntent');
    const intent = (await onCreatePaymentIntent(data)).data as Stripe.PaymentIntent;
    thunkAPI.dispatch(slice.actions.setCreatingIntent(false));
    return intent;
  }
);

const completeDonation = createAsyncThunk<any, {
  stripe: any,
  elements: any,
}, AppThunkApiConfig>(
  'donations/completeDonation',
  async ({
    stripe,
    elements
  }, thunkAPI) => {

    const {
      donations: { paymentIntent }
    } = await thunkAPI.getState();

    if (!stripe || !elements || !paymentIntent) {
      console.warn('@@ Stripe not loaded...');
      return null;
    }

    const processError = (_error: Stripe.StripeError) => {
      thunkAPI.dispatch(rootActions.showInfo({
        text: _error.message || 'Error',
        severity: 'error'
      }));
      thunkAPI.dispatch(slice.actions.setError(_error.message));
      thunkAPI.dispatch(slice.actions.setProcessing(false));
      bAnalytics.track(Events.FailedDonation, {
        step: 'payment',
        errorCode: _error.code,
        errorMessage: _error.message
      });
      throw Error(_error.message);
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!,
    });

    thunkAPI.dispatch(slice.actions.setProcessing(true));

    if (error) {
      processError(error);
    }

    const {
      error: paymentError
    } = await stripe.confirmCardPayment(
      paymentIntent.client_secret!,
      { payment_method: paymentMethod?.id }
    );

    if (paymentError) {
      processError(paymentError);
    }

    bAnalytics.track(Events.CompletedDonation, {
      revenue: (paymentIntent.amount / 100).toFixed(2),
      currency: 'USD'
    });

    thunkAPI.dispatch(slice.actions.setProcessing(false));
  }
);

const slice = createSlice({
  name: 'donations',
  initialState: INITIAL_STATE,
  reducers: {
    showModal: (state, action: PayloadAction<boolean>) => {
      state.showDonationModal = action.payload;
      if (!action.payload) {
        state.donationComplete = false;
      }
    },
    startDonation: (state, action: PayloadAction<Pick<State, 'donationCause' | 'donationChallenge'>>) => {
      state.showDonationModal = true;
      state.donationCause = action.payload.donationCause;
      state.donationChallenge = action.payload.donationChallenge;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.donationError = action.payload;
    },
    setProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
    setCreatingIntent: (state, action: PayloadAction<boolean>) => {
      state.creatingIntent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPaymentIntent.fulfilled, (state, action) => {
      state.paymentIntent = action.payload;
    });

    builder.addCase(completeDonation.fulfilled, (state, action) => {
      state.paymentIntent = undefined;
      state.donationComplete = true;
    });
  }
});

export const actions = {
  ...slice.actions,
  createPaymentIntent,
  completeDonation
};

export default slice.reducer;
