import { Id, Profile } from 'bee';
import { useEffect } from 'react';
import { actions as profileActions } from '../slices/profiles';
import { useAppDispatch, useAppSelector } from '../store';

export const DEFAULT_PROFILE: Profile = {
  username: 'Bee User',
};

export const useProfile = (userId?: string): (Profile & Id) | undefined => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(({ profiles: { profilesLoading } }) =>
    userId ? profilesLoading[userId] : undefined
  );

  const profile = useAppSelector(
    ({ profiles: { profiles } }) => profiles[userId ?? '']
  );

  useEffect(() => {
    if (!userId || loading !== undefined) {
      return;
    }

    dispatch(profileActions.getSingleProfile(userId));
  }, [loading, dispatch, userId]);

  if (loading) {
    return undefined;
  }

  return profile;
};

export default useProfile;
