import {
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useLocale from '../../../hooks/useLocale';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useSearch from '../../../hooks/useSearch';
import useUser from '../../../hooks/useUser';
import { actions as authActions } from '../../../slices/auth';
import {
  actions as contentActions,
  supportedLocales,
} from '../../../slices/content';
import { actions as postActions } from '../../../slices/posts';
import { useAppDispatch } from '../../../store';
import { ROUTES } from '../../BRouter';
import BSearchField from '../../inputs/BSearchField';
import BDropDownMenu from './BDropDownMenu';
import BLogo from './BLogo';
import BNotificationsMenu from './BNotificationsMenu';
import useShowSignup from '../../../hooks/useShowSignup';
import useProfile from '../../../hooks/useProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  toolbar: {
    justifyContent: 'space-between',
    height: theme.spacing(6),
  },
  logoLink: {
    marginRight: 'auto',
  },
  searchField: {
    marginTop: theme.spacing(0.25),
    flex: 1,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
  },
  button: {
    height: '100%',
    fontSize: '1rem',
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  buttonIcon: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonBorder: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  buttonPrimary: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
}));

const BNavBar = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const content = useLocalizedContent('nav');
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const user = useUser();
  const profile = useProfile();
  const search = useSearch();
  const locale = useLocale();
  const [business, setBusiness] = useState(true);
  const showSignupModal = useShowSignup();

  useEffect(() => {
    if (location.hash === '#register') {
      showSignupModal();
    }
  }, [setBusiness, location, business, showSignupModal]);

  // NOTE: this mechanism only works with 2 supported locales.
  // for more supported locales, this won't work.
  const alternateLocale = supportedLocales.find(
    (element) => element !== locale
  )!;

  const onLocaleChange = useCallback(() => {
    dispatch(contentActions.localeChanged(alternateLocale));
  }, [alternateLocale, dispatch]);

  const onSearch = useCallback((searchTerm: string) => search(searchTerm), [
    search,
  ]);

  const onSignup = useCallback(() => {
    dispatch(authActions.showSignupModal());
  }, [dispatch]);

  const onLogin = useCallback(() => {
    dispatch(authActions.showLoginModal());
  }, [dispatch]);

  const onCreatePost = useCallback(() => {
    const userRoute = `/user/${user!.uid}`;

    const canPostHere = [
      ROUTES.getInspired,
      ROUTES.myPosts,
      userRoute,
    ].includes(location.pathname);

    if (!canPostHere) {
      history.push(ROUTES.myPosts);
    }

    window.scrollTo(0, 0);

    dispatch(postActions.setShowCreatePost(true));
  }, [dispatch, history, location.pathname, user]);

  if (content.__empty) {
    return null;
  }

  return (
    <AppBar color="default" elevation={1}>
      <Toolbar className={classes.toolbar} variant="dense" disableGutters>
        <Link className={classes.logoLink} title="Bee Home" to={ROUTES.home}>
          <BLogo />
        </Link>
        <BSearchField className={classes.searchField} onSearch={onSearch} />
        {(user || !isMd) && (
          <BDropDownMenu
            className={clsx(
              classes.button,
              classes.buttonBorder,
              classes.buttonIcon
            )}
            onLocaleChange={onLocaleChange}
            onLogin={onLogin}
            onSignup={onSignup}
            onCreatePost={onCreatePost}
            isBusiness={business}
          />
        )}
        {user && (
          <BNotificationsMenu
            className={clsx(
              classes.button,
              classes.buttonBorder,
              classes.buttonIcon
            )}
          />
        )}
        {isMd &&
          (user ? (
            <>
              <Button
                variant="outlined"
                className={clsx(classes.button, classes.buttonBorder)}
                component={Link}
                to={ROUTES.getInspired}
              >
                {content.home}
              </Button>
              {user.emailVerified && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.button, classes.buttonPrimary)}
                  onClick={onCreatePost}
                >
                  {content.create_post_cta}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                className={clsx(classes.button, classes.buttonBorder)}
                onClick={onLocaleChange}
              >
                FR/EN
              </Button>
              <Button
                variant="outlined"
                className={clsx(classes.button, classes.buttonBorder)}
                onClick={onSignup}
              >
                {content.signup_text}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={onLogin}
              >
                {content.login_text}
              </Button>
            </>
          ))}
      </Toolbar>
    </AppBar>
  );
};

export default BNavBar;
