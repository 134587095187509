import { useCallback, useEffect, useState } from 'react';

export default (
  nextPage: () => Promise<void>,
  shouldLoad: boolean
): [(el: HTMLElement | null) => void, boolean] => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  const scrollObserver = useCallback(
    (n: HTMLElement) => {
      if (!shouldLoad) {
        return;
      }

      new IntersectionObserver((entries) => {
        entries.forEach(async (en) => {
          if (en.intersectionRatio > 0) {
            setLoading(true);
            await nextPage();
            setLoading(false);
          }
        });
      }).observe(n);
    },
    [nextPage, shouldLoad]
  );

  useEffect(() => {
    if (node) {
      scrollObserver(node);
    }
  }, [scrollObserver, node]);

  return [setNode, loading];
};
