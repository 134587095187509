import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, Id } from 'bee';

interface State {
  notifications: (Notification & Id)[];
}

const INITIAL_STATE: State = {
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState: INITIAL_STATE,
  reducers: {
    setNotifications: (state, action: PayloadAction<(Notification & Id)[]>) => {
      state.notifications = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
