import axios from 'axios';
import { CreateVideoUploadUrlResponse } from 'bee';
import firebase from 'firebase/app';
import { firebaseConfig } from '../utils/firebase';

const PROFILE_IMAGE_PATH = 'images/profiles';

export default class MediaService {
  static instance = new MediaService();

  extractExtension(filename: string) {
    let ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
      return ext[0];
    } else {
      return '';
    }
  }

  getProfileImageRef(userId: string) {
    return firebase.storage().ref(`${PROFILE_IMAGE_PATH}/${userId}`);
  }

  getProfileImageUrl(userId: string) {
    const path = this.getProfileImageRef(userId).fullPath;

    return `https://firebasestorage.googleapis.com/v0/b/${
      firebaseConfig.storageBucket
    }/o/${encodeURIComponent(path)}?alt=media`;
  }

  async uploadImage(
    ref: firebase.storage.Reference,
    file: File,
    onProgress?: (progress: number) => void
  ) {
    const upload = ref.put(file);

    upload.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      onProgress?.(snapshot.bytesTransferred / snapshot.totalBytes);
    });

    await upload;

    return ref.getDownloadURL();
  }

  async uploadVideo(
    postId: string,
    file: File,
    onProgress?: (progress: number) => void
  ) {
    const { url } = (
      await firebase.apps[0]
        .functions('us-central1')
        .httpsCallable('onCreateVideoUploadUrl')({ postId })
    ).data as CreateVideoUploadUrlResponse;

    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (e: ProgressEvent) => {
        onProgress?.(e.loaded / e.total);
      },
    });
  }
}
