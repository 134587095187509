import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useAppSelector } from '../../store';

const useStyles = makeStyles({
  root: {
    fontSize: 14,
  },
  filledInfo: {
    background: 'linear-gradient(270deg, #52c8c0 0%, #46aea7 100%)',
  },
  filledSuccess: {
    background: 'linear-gradient(270deg, #52c8c0 0%, #46aea7 100%)',
  },
  filledError: {
    background: 'linear-gradient(270deg, #f32e20 0%, #f55347 100%)',
  },
});

const BSnackbar = () => {
  const classes = useStyles();

  const info = useAppSelector(({ root: { info } }) => info);

  return (
    <Snackbar open={info?.open ?? false}>
      <Alert
        classes={classes}
        variant="filled"
        severity={info?.severity}
        elevation={6}
      >
        {info?.text}
      </Alert>
    </Snackbar>
  );
};

export default BSnackbar;
