import { IconButton, Tooltip } from '@material-ui/core';
import Input, {
  InputProps as StandardInputProps,
} from '@material-ui/core/Input/Input';
import { makeStyles } from '@material-ui/core/styles';
import { LocationOn } from '@material-ui/icons';
import Search from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import React, { memo, useEffect, useRef, useState } from 'react';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import useQueryParams from '../../hooks/useQueryParams';
import useSearchCoords from '../../hooks/useSearchCoords';
import useSearchBounds from '../../hooks/useSearchBounds';
import BLocationSearch from './BLocationSearch';
import BDialog from '../layout/BDialog';

interface Props extends StandardInputProps {
  onSearch?: (value: string) => void;
  onValueChange?: (value: string) => void;
  disableLocation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  input: {
    textTransform: 'uppercase',
    paddingRight: theme.spacing(2),
  },
  locationDialogContent: {
    padding: 0,
  },
  locationDialogPaper: {
    overflow: 'visible',
  },
}));

const BSearchField: React.FC<Props> = memo(
  ({
    onValueChange,
    onSearch,
    placeholder,
    defaultValue,
    className,
    disableLocation,
    ...rest
  }) => {
    const content = useLocalizedContent('search');
    const classes = useStyles();
    const textField = useRef<HTMLInputElement>();
    const params = useQueryParams();
    const searchCoords = useSearchCoords();
    const searchBounds = useSearchBounds();
    const [showMapInput, setShowMapInput] = useState(false);

    // this is to avoid double submits due to onSearch and onSubmit events both firing
    const _onSearch = useRef(
      debounce(() => {
        (onValueChange ?? noop)(textField.current!.value);
        (onSearch ?? noop)(textField.current!.value);
      }, 10)
    );

    useEffect(() => {
      const fn = _onSearch.current;
      const el = textField.current;

      // eslint-disable-next-line no-unused-expressions
      el?.addEventListener('search', fn);

      return () => el?.removeEventListener('search', fn);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearchClick = () => {
      const focus = () =>
        window.setTimeout(() => textField.current!.focus(), 10);

      _onSearch.current();

      if (textField.current!.value) {
        focus();
      }
    };

    const _onSubmit = (e: Event | React.FormEvent) => {
      e.preventDefault();

      _onSearch.current();
    };

    const _onChange = () => (onValueChange ?? noop)(textField.current!.value);

    useEffect(() => {
      const query = params.get('q');
      if (query && textField.current) {
        textField.current.value = query;
      } else if (!query && textField.current) {
        textField.current.value = '';
      }
    }, [params, onValueChange, onSearch, textField]);

    return (
      <form className={className} onSubmit={_onSubmit} role="search">
        <Input
          type="search"
          placeholder={placeholder ?? content.search_placeholder}
          inputRef={textField}
          onChange={_onChange}
          inputProps={{
            className: classes.input,
          }}
          startAdornment={
            <IconButton
              disabled={rest.disabled}
              onClick={onSearchClick}
              size="small"
            >
              <img
                src="/media/bee-b-small.png"
                alt="Bee Logo"
                style={{ height: 25, width: 'auto' }}
              />
            </IconButton>
          }
          endAdornment={
            !disableLocation && (
              <Tooltip
                title={
                  searchBounds || searchCoords
                    ? content.modify_location
                    : content.search_by_location
                }
                arrow
              >
                <IconButton onClick={() => setShowMapInput(!showMapInput)}>
                  <LocationOn />
                </IconButton>
              </Tooltip>
            )
          }
          defaultValue={defaultValue}
          {...rest}
        />
        <BDialog
          open={showMapInput}
          onClose={() => setShowMapInput(false)}
          title={content.location_search_title}
          scroll="body"
          classes={{
            paper: classes.locationDialogPaper,
          }}
          ContentProps={{
            className: classes.locationDialogContent,
          }}
          maxWidth="xs"
          fullWidth
          disableContentWrapper
          disableRestoreFocus
        >
          <BLocationSearch onClose={() => setShowMapInput(false)} />
        </BDialog>
      </form>
    );
  }
);

export default BSearchField;
