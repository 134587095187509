import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';
import { enCA, frCA } from 'date-fns/locale';
import useLocale from './useLocale';

type DateType = Date | number;

const LOCALE_MAP = {
  'en-ca': enCA,
  'fr-ca': frCA,
};

export default () => {
  const locale = useLocale();
  const dLocale = LOCALE_MAP[locale] ?? enCA;

  return {
    formatDistance: (input: DateType) =>
      formatDistance(input, Date.now(), { locale: dLocale, addSuffix: true }),
    format: (input: DateType, str = 'PPpp') =>
      format(input, str, { locale: dLocale }),
  };
};
