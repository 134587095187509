import React, { memo } from 'react';
import Select, { Props } from 'react-select';
import useAllCauses from '../../../hooks/useAllCauses';

export interface CauseSelectProps extends Props {}

export const CauseSelect = memo((props: CauseSelectProps) => {
  const causes = useAllCauses();
  return (
    <Select
      {...props}
      placeholder={'Cause'}
      options={[
        { value: '', label: '-' },
        ...(causes?.map((cause) => ({
          value: cause.id,
          label: cause.name,
        })) || []),
      ]}
    />
  );
});

export default CauseSelect;
