import { Id, ProfileMeta } from 'bee';
import { useEffect, useState } from 'react';
import ProfilesMetaService from '../services/ProfilesMetaService';

const useProfileMeta = (id?: string) => {
  const [meta, setMeta] = useState<ProfileMeta & Id>();

  useEffect(() => {
    (async () => {
      if(!id){
        return;
      }
      const meta = await ProfilesMetaService.instance.get(id);
      setMeta(meta);
    })();
  }, [id])

  return meta;
};

export default useProfileMeta;
