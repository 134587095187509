import {
  Badge,
  Button,
  ListItem,
  ListItemText,
  Menu,
  MenuProps,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationsOutlined } from '@material-ui/icons';
import { Id, Notification } from 'bee';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useUser from '../../../hooks/useUser';
import NotificationsService, {
  PAGE_SIZE as NOTIFICATION_PAGE_SIZE,
} from '../../../services/NotificationsService';
import ProfilesService from '../../../services/ProfilesService';
import { useAppSelector } from '../../../store';
import NotificationListItem from './NotificationListItem';

interface Props {
  className?: string;
}

const DROPDOWN_MENU_ID = 'notifications-menu';

const useStyles = makeStyles({
  menuPaper: {
    width: 360,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
});

const BNotificationsMenu: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const content = useLocalizedContent('nav');
  const [anchor, setAnchor] = useState<HTMLButtonElement>();
  const user = useUser();
  const [lastNotificationsChecked, setLastNotificationsChecked] = useState(0);
  const [hasMoreAdditional, setHasMoreAdditional] = useState(true);
  const [additionalNotifications, setAdditionalNotifications] = useState<
    (Notification & Id)[]
  >([]);

  const notifications = useAppSelector(
    ({ notifications: { notifications } }) => notifications
  );

  const hasMore =
    notifications.length >= NOTIFICATION_PAGE_SIZE && hasMoreAdditional;
  const allNotifications = [...notifications, ...additionalNotifications];

  const notificationsChecked = useAppSelector(
    ({
      profiles: {
        appPreferences: { notificationsChecked },
      },
    }) => notificationsChecked!
  );

  const unreadNotifications = useMemo(
    () =>
      notifications.filter((n) => n.timestamp > notificationsChecked).length,
    [notifications, notificationsChecked]
  );

  const menuProps: Partial<MenuProps> = useMemo(
    () => ({
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      transformOrigin: { horizontal: 'left', vertical: 'top' },
    }),
    []
  );

  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setLastNotificationsChecked(notificationsChecked ?? 0);
      ProfilesService.instance.setNotificationsRead(user!.uid);
      setAnchor(e.currentTarget);
    },
    [notificationsChecked, user]
  );

  const loadMore = useCallback(async () => {
    if (!allNotifications.length) {
      return;
    }

    const n = (
      await NotificationsService.instance.loadOlder({
        userId: user!.uid,
        olderThan: allNotifications[allNotifications.length - 1].timestamp,
      })
    ).docs.map((d) => d.data());

    setAdditionalNotifications([...additionalNotifications, ...n]);

    if (n.length < NOTIFICATION_PAGE_SIZE) {
      setHasMoreAdditional(false);
    }
  }, [additionalNotifications, allNotifications, user]);

  const onClose = useCallback(() => {
    setAnchor(undefined);
  }, []);

  const open = !!anchor;

  useEffect(() => {
    if (!open) {
      setAdditionalNotifications([]);
      setHasMoreAdditional(true);
    }
  }, [open]);

  if (content.__empty) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        className={className}
        aria-controls={DROPDOWN_MENU_ID}
        aria-haspopup="true"
        onClick={onOpen}
        title={content.notifications}
      >
        <Badge
          badgeContent={unreadNotifications}
          color="primary"
          overlap="circle"
          max={9}
        >
          <NotificationsOutlined />
        </Badge>
      </Button>
      <Menu
        id={DROPDOWN_MENU_ID}
        classes={{
          paper: classes.menuPaper,
        }}
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        getContentAnchorEl={null}
        keepMounted
        {...menuProps}
      >
        {open &&
          allNotifications.map((n) => (
            <NotificationListItem
              key={n.id}
              notification={n}
              isFresh={n.timestamp > lastNotificationsChecked}
              onClick={onClose}
            />
          ))}
        {hasMore && (
          <ListItem button onClick={loadMore} className={classes.buttonRow}>
            <Typography variant="body2">
              {content.show_more_notifications}
            </Typography>
          </ListItem>
        )}
        {!notifications.length && (
          <ListItem>
            <ListItemText primary={content.no_notifications} />
          </ListItem>
        )}
      </Menu>
    </>
  );
};

export default BNotificationsMenu;
