import { Cause, Id } from 'bee';
import { useEffect, useState } from 'react';
import CausesService from '../services/CausesService';
import useLocale from './useLocale';

export const useAllCauses = () => {
  const [causes, setCauses] = useState<(Cause & Id)[]>();
  const locale = useLocale().split('-')[0];

  useEffect(() => {
    (async () => {
      const _causes = await CausesService.instance.list();
      const translated = await Promise.all(_causes.map(
        async (cause) => {
          return (
            await CausesService.instance.getTranslation(cause.id, locale)
          ) as (Cause & Id);
        }
      ));
      setCauses(translated);
    })();
  }, [setCauses, locale]);

  return causes;
};

export default useAllCauses;