import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileCopyOutlined, Link } from '@material-ui/icons';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { actions as postActions } from '../../../slices/posts';
import { useAppDispatch, useAppSelector } from '../../../store';
import { bAnalytics, Events } from '../../../utils/analytics';
import BDialog from '../BDialog';

const useStyles = makeStyles({
  root: {
    width: 320,
  },
  content: {
    padding: 0,
  },
  listItem: {
    display: 'none',
  },
  copyButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    padding: 0,
  },
  copyButtonLabel: {
    fontSize: 18,
  },
  copyButtonIcon: {
    fontSize: 18,
  },
  copyButtonListItem: {},
});

const BShareDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const post = useAppSelector(({ posts: { sharePost } }) => sharePost);
  const content = useLocalizedContent('my_posts');

  const input = useRef<HTMLInputElement | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const facebookShareButton = useRef<HTMLButtonElement | null>(null);
  const twitterShareButton = useRef<HTMLButtonElement | null>(null);
  const linkedInShareButton = useRef<HTMLButtonElement | null>(null);
  const emailShareButton = useRef<HTMLButtonElement | null>(null);

  const onClose = useCallback(
    (medium?: string) => () => {
      if (!!medium) {
        bAnalytics.track(Events.Shared, { ...post, medium });
      }

      dispatch(postActions.setSharePost(null));
    },
    [dispatch, post]
  );

  const onInputClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    input.current?.select();
  }, []);

  const onCopy = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      input.current?.select();
      document.execCommand('copy');
      e.currentTarget.focus();

      bAnalytics.track(Events.CopiedShareLink, { ...post });

      setCopySuccess(true);

      window.setTimeout(() => {
        setCopySuccess(false);
      }, 4000);
    },
    [post]
  );

  const build =
    process.env.REACT_APP_BUILD?.substr(0, 2) ?? `${Date.now()}`.substr(-2);

  const shareUrl = `${window.location.origin}/post/${post?.id}?b=${build}&utm_medium=web&utm_source=share-popup`;

  const inputProps = useMemo(
    () => ({
      readOnly: true,
    }),
    []
  );

  const contentProps = useMemo(
    () => ({
      classes: { root: classes.content },
    }),
    [classes]
  );

  const shareButton = (
    title: string,
    Icon: typeof FacebookIcon,
    ref: React.MutableRefObject<HTMLButtonElement | null>
  ) => (
    <ListItem onClick={() => ref.current?.click()} button>
      <ListItemIcon>
        <Icon size={32} round />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );

  return (
    <BDialog
      classes={{ paper: classes.root }}
      ContentProps={contentProps}
      id="share"
      open={!!post}
      title={content.share}
      onClose={onClose()}
      fullWidth={false}
    >
      <List>
        {/*https://github.com/mui-org/material-ui/issues/14971*/}
        <Tooltip
          title={
            copySuccess
              ? content.copied_to_clipboard
              : content.copy_to_clipboard
          }
          arrow
          open={copySuccess}
          placement={'top'}
          leaveDelay={800}
        >
          <ListItem
            button={document.queryCommandSupported('copy') as any}
            onClick={onCopy as any}
          >
            <ListItemIcon>
              <Button
                variant="contained"
                color="primary"
                className={classes.copyButton}
                onClick={onCopy}
                classes={{
                  label: classes.copyButtonLabel,
                }}
              >
                {document.queryCommandSupported('copy') ? (
                  <FileCopyOutlined
                    fontSize="small"
                    className={classes.copyButtonIcon}
                  />
                ) : (
                  <Link fontSize="small" className={classes.copyButtonIcon} />
                )}
              </Button>
            </ListItemIcon>
            <ListItemText
              primary={
                <TextField
                  inputRef={input}
                  variant="outlined"
                  fullWidth
                  value={shareUrl}
                  inputProps={inputProps}
                  onClick={onInputClick}
                />
              }
            />
          </ListItem>
        </Tooltip>
        {shareButton('Facebook', FacebookIcon, facebookShareButton)}
        <FacebookShareButton
          ref={facebookShareButton}
          className={classes.listItem}
          aria-hidden="true"
          url={shareUrl}
          quote={post?.description}
          onShareWindowClose={onClose('facebook')}
          tabIndex={-1}
        >
          {null}
        </FacebookShareButton>
        {shareButton('Twitter', TwitterIcon, twitterShareButton)}
        <TwitterShareButton
          ref={twitterShareButton}
          className={classes.listItem}
          url={shareUrl}
          title={post?.description}
          onShareWindowClose={onClose('twitter')}
        >
          {null}
        </TwitterShareButton>
        {shareButton('LinkedIn', LinkedinIcon, linkedInShareButton)}
        <LinkedinShareButton
          ref={linkedInShareButton}
          className={classes.listItem}
          url={shareUrl}
          title={post?.description}
          onShareWindowClose={onClose('linkedin')}
        >
          {null}
        </LinkedinShareButton>
        {shareButton('E-Mail', EmailIcon, emailShareButton)}
        <EmailShareButton
          ref={emailShareButton}
          className={classes.listItem}
          url={shareUrl}
          subject="A Bee member wants to share a post with you"
          beforeOnClick={() => Promise.resolve(onClose('email')())}
        >
          {null}
        </EmailShareButton>
      </List>
    </BDialog>
  );
};

export default BShareDialog;
