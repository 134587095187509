import { Id, Post, PostComment } from 'bee';
import { useEffect, useState } from 'react';
import { unwrapSnapshot } from '../services/FirestoreService';
import PostsService from '../services/PostsService';

interface DescendantsOpts {
  limit?: number;
}

export interface PostDataOpts {
  descendants?: DescendantsOpts;
}

export default (postId: string, subscribe = true, opts?: PostDataOpts) => {
  const [post, setPost] = useState<(Post & Id) | null>();
  const [comments, setComments] = useState<(PostComment & Id)[]>([]);
  const [descendants, setDescendants] =
    useState<(Post & Id)[]>([]);

  useEffect(() => {
    if (!subscribe) {
      return;
    }
    if (!postId) {
      return;
    }

    const postSubscription = PostsService.instance
      .doc(postId)
      .onSnapshot((sn) => setPost(sn.data() ?? null));

    const commentsSubscription = PostsService.instance
      .commentsQuery(postId)
      .onSnapshot((sn) => setComments(unwrapSnapshot(sn)));

    const descendantsSubscription = PostsService.instance
      .descendants$(postId, (posts) =>{
        setDescendants(posts);
      }, opts?.descendants?.limit);


    return () => {
      postSubscription?.();
      commentsSubscription?.();
      descendantsSubscription?.();
    };
  }, [subscribe, postId, opts]);

  return {
    post,
    comments,
    descendants,
  };
};
