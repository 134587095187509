import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, PostComment } from 'bee';
import { LinkifyOptions } from 'linkifyjs/react';
import React, { useMemo } from 'react';
import useI18nFormat from '../../../../hooks/useI18nFormat';
import useProfile from '../../../../hooks/useProfile';
import { bAnalytics, Events } from '../../../../utils/analytics';
import BProfileLink from '../../../utils/BProfileLink';
import BUserAvatar from '../../../utils/BUserAvatar';
import BLinkify from './BLinkify';

interface Props {
  className?: string;
  comment: PostComment & Id;
}

const useStyles = makeStyles((theme) => ({
  username: {
    color: theme.palette.text.primary,
    display: 'inline-block',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  comment: {
    whiteSpace: 'pre-wrap',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    marginTop: theme.spacing(0.5),
  },
}));

const Comment: React.FC<Props> = ({ className, comment }) => {
  const classes = useStyles();
  const format = useI18nFormat();
  const username = useProfile(comment.userId)?.username;

  const linkifyOptions: LinkifyOptions = useMemo(
    () => ({
      attributes: {
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          bAnalytics.track(Events.ClickedCommentLink, {
            ...comment,
            url: e.currentTarget.href,
          });
        },
      },
    }),
    [comment]
  );

  return (
    <Box component="li" className={className} display="flex">
      <BProfileLink userId={comment.userId}>
        <BUserAvatar className={classes.avatar} userId={comment.userId} />
      </BProfileLink>
      <Box marginLeft={2}>
        <BProfileLink userId={comment.userId}>
          <Typography className={classes.username} variant="body2">
            {username}
          </Typography>
        </BProfileLink>{' '}
        <BLinkify
          TypographyProps={{ className: classes.comment, variant: 'body2' }}
          options={linkifyOptions}
        >
          {comment.comment}
        </BLinkify>
        <Typography variant="caption" component="p" color="textSecondary">
          {format.formatDistance(comment.commentTimestamp)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Comment;
