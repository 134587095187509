import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, Post } from 'bee';
import React, { memo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { ROUTES } from '../../../BRouter';
import BPostDescendant from './BPostDescendant';
import { ChevronRight } from '@material-ui/icons';

export interface BPostDescendantsProps {
  descendants: (Post & Id)[];
  postId: string;
}

const useStyles = makeStyles((theme) => ({
  arrow: {
    position: 'absolute',
    right: 0,
    top: '50%',
    height: 40,
    width: 40,
    marginTop: -20,
    background: 'white',
    borderRadius: 20,
    boxShadow: '0 2px 5px -1px rgba(0,0,0)',
    transform: 'translate(0,0)',
    transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',

    '&:hover': {
      boxShadow: '0 5px 5px -1px rgba(0,0,0,0.5)',
      transform: 'translate(0,-3px)',
      transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',
    },
  },
  grid: {
    position: 'relative',
  },
}));

export const BPostDescendants = memo(
  ({ descendants, postId }: BPostDescendantsProps) => {
    const classes = useStyles();
    return descendants.length ? (
      <Box py={2} px={1.5}>
        <Grid container spacing={2} className={classes.grid}>
          {descendants.slice(0, 3).map((post) => (
            <Grid item xs={12} sm={4} key={post.id}>
              <BPostDescendant post={post} />
            </Grid>
          ))}
          {descendants.length > 3 && (
            <Link
              to={generatePath(ROUTES.singlePost, {
                id: postId,
              })}
            >
              <ChevronRight className={classes.arrow} />
            </Link>
          )}
        </Grid>
      </Box>
    ) : null;
  }
);

export default BPostDescendants;
