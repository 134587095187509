import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

export interface BOnscreenToggleProps {
  onScreen?: Function;
  offScreen?: Function;
}

export const BOnscreenToggle = memo(
  ({ onScreen, offScreen }: BOnscreenToggleProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    const scrollObserver = useCallback(
      (n: HTMLElement) => {
        new IntersectionObserver((entries) => {
          entries.forEach(async (entry) => {
            if (entry.intersectionRatio > 0 && !visible) {
              setVisible(true);
            }
            if (entry.intersectionRatio === 0 && visible) {
              setVisible(false);
            }
          });
        }).observe(n);
      },
      [setVisible, visible]
    );

    useEffect(() => {
      if (ref.current) {
        scrollObserver(ref.current);
      }
    }, [scrollObserver]);

    useEffect(() => {
      if (visible && onScreen) {
        onScreen();
      }
      if (!visible && offScreen) {
        offScreen();
      }
    }, [visible, onScreen, offScreen]);

    return <div ref={ref} />;
  }
);

export default BOnscreenToggle;
