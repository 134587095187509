export enum Environments {
  Production = 'production',
  Development = 'development'
}

/**
 * Return the current environment
 * @returns {'development' | 'production'}
 */
export const getEnv = () => {
  return process.env.REACT_APP_PLATFORM as Environments;
};

/**
 * Takes a function, an environment, and
 * if the environment is the current one,
 * runs the function.
 * @param env
 * @param fn
 */
export const inEnv = (env: Environments, fn: Function) => {
  if (getEnv() === env) {
    fn();
  }
};

/**
 * Takes a function and only runs it in prod.
 * @param fn
 */
export const inProd = (fn: Function) => {
  inEnv(Environments.Production, fn);
};

/**
 * Takes a function and only runs it in development.
 * @param fn
 */
export const inDev = (fn: Function) => {
  inEnv(Environments.Development, fn);
};
