import { IconButton, Menu, MenuItem, MenuProps } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { Id, Post } from 'bee';
import React, { memo, useCallback, useMemo, useState } from 'react';
import useLocalizedContent from '../../../../hooks/useLocalizedContent';
import useShowEditPost from '../../../../hooks/useShowEditPost';
import useUser from '../../../../hooks/useUser';
import PostsService from '../../../../services/PostsService';
import { actions as postActions } from '../../../../slices/posts';
import { useAppDispatch } from '../../../../store';

interface Props {
  post: Post & Id;
}

const PostMoreMenu: React.FC<Props> = memo(({ post }) => {
  const content = useLocalizedContent('my_posts');
  const dispatch = useAppDispatch();
  const user = useUser();

  const showEditPost = useShowEditPost();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>();

  const onToggleMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setMenuAnchor(undefined);
  }, []);

  const onReportPost = useCallback(async () => {
    onClose();

    await PostsService.instance.reportPost(post.id);
  }, [onClose, post.id]);

  const onDeletePost = useCallback(async () => {
    onClose();

    await dispatch(postActions.deletePost({ postId: post.id }));
  }, [dispatch, onClose, post.id]);

  const menuProps: Partial<MenuProps> = useMemo(
    () => ({
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      transformOrigin: { horizontal: 'right', vertical: 'top' },
    }),
    []
  );

  // TODO: remove user check once reporting is figured out
  if (!user || post.userId !== user.uid) {
    return null;
  }

  return (
    <>
      <IconButton aria-label="settings" size="small" onClick={onToggleMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        id={post.id}
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={onClose}
        getContentAnchorEl={null}
        keepMounted
        {...menuProps}
      >
        {post.userId === user.uid ? (
          [
            <MenuItem key="delete" onClick={onDeletePost}>
              {content.delete}
            </MenuItem>,
            <MenuItem
              key="edit"
              onClick={() => {
                showEditPost({ post, show: true });
              }}
            >
              {content.edit}
            </MenuItem>,
          ]
        ) : (
          <MenuItem onClick={onReportPost}>{content.report}</MenuItem>
        )}
      </Menu>
    </>
  );
});

export default PostMoreMenu;
