import { Profile } from 'bee';
import { UserObject } from '../services/AuthService';
import { inDev, inProd } from './environment';
import firebase from 'firebase/app';

/**
 * All the possible events we should be sending.
 */
export enum Events {
  Registered = 'Registered',
  LoggedIn = 'Logged In',
  LoggedOut = 'Logged Out',
  PasswordReset = 'Password Reset',
  CreatedPost = 'Created Post',
  CreatedComment = 'Created Comment',
  UpdatedProfile = 'Updated Profile',
  ClickedAdvertisement = 'Clicked Advertisement',
  Liked = 'Liked',
  SuperLiked = 'Super Liked',
  ClickedPostLink = 'Clicked post link',
  ClickedProfileLink = 'Clicked profile link',
  ClickedCommentLink = 'Clicked comment link',
  Searched = 'Searched',
  ClickedDonate = 'Clicked Donate',
  SetDonationAmount = 'Set Donation Amount',
  CompletedDonation = 'Completed Donation',
  FailedDonation = 'Failed Donation',
  CopiedShareLink = 'Copied Share Link',
  Shared = 'Shared',
}

/**
 * Returns the JSON representation of the user.
 * @returns {*}
 */
export const currentUserJson = () => {
  const user = firebase.auth().currentUser;
  return user?.toJSON() as UserObject | undefined;
};

/**
 * Class used to organize the tracking calls.
 * Makes sur that analytics calls are only run in prod.
 */
class Analytics {
  _call = (method: string, args: any[]) => {
    inProd(() => {
      try {
        window.analytics[method].call(null, ...args);
      } catch (e) {
        console.log('Analytics not ready.', e);
      }
    });
    inDev(() => {
      console.log(`Calling analytics. ${method}, with args:`, args);
    });
  };

  track = (...args: any[]) => {
    this._call('track', args);
  };

  identify = (...args: any[]) => {
    this._call('identify', args);
  };

  reset = (...args: any[]) => {
    this._call('reset', args);
  };

  page = (...args: any[]) => {
    setTimeout(() => {
      this._call('page', args);
    }, 50);
  };

  identifyUser = async () => {
    const usr = await currentUserJson();

    if (!usr) {
      return;
    }

    const {
      uid,
      displayName,
      email,
      emailVerified,
      phoneNumber: phone,
      lastLoginAt,
      createdAt,
    } = usr;
    this.identify(uid, {
      createdAt: createdAt
        ? new Date(parseInt(createdAt)).toISOString()
        : undefined,
      name: displayName,
      lastLoginAt: lastLoginAt
        ? new Date(parseInt(lastLoginAt)).toISOString()
        : undefined,
      email,
      emailVerified,
      phone,
    });
  };

  updateProfile = async (profile: Profile) => {
    const user = await currentUserJson();

    if (!user) {
      return;
    }

    bAnalytics.track(Events.UpdatedProfile, profile);
    this.identify(user.uid, profile);
  };
}

export const bAnalytics = new Analytics();
