import { Id } from 'bee';
import firebase from 'firebase/app';
import type { firestore } from 'firebase';

(window as any).firestore = firebase.firestore;

export function converterWithId<T>() {
  return {
    toFirestore(post: T & Id): firestore.DocumentData {
      const { id, ...data } = post;
      return data;
    },
    fromFirestore(
      snapshot: firestore.QueryDocumentSnapshot,
      options: firestore.SnapshotOptions
    ): T & Id {
      const data = snapshot.data(options)!;
      return { ...data, id: snapshot.id } as T & Id;
    },
  };
}

export async function unwrapQuery<T>(
  sn: Promise<firestore.QuerySnapshot<T & Id>>
) {
  return unwrapSnapshot(await sn);
}

export function unwrapSnapshot<T>(sn: firestore.QuerySnapshot<T & Id>) {
  return sn.docs.map((d) => d.data());
}

export function generateId() {
  return firebase.firestore().collection('id-please').doc().id;
}

export default class FirestoreService<T extends object = {}> {
  constructor(protected path: string) {}

  collection() {
    return firebase
      .firestore()
      .collection(this.path)
      .withConverter(converterWithId<T>());
  }

  doc(id: string) {
    return this.collection().doc(id);
  }

  async get(id: string) {
    return (await this.doc(id).get()).data();
  }

  list() {
    return unwrapQuery(this.collection().get());
  }

  delete(id: string) {
    return this.doc(id).delete();
  }

  set(id: string, data: T) {
    return this.collection()
      .doc(id)
      .set({ id: '', ...data });
  }
}
