import { Id, Notification } from 'bee';
import firebase from 'firebase/app';
import { converterWithId, unwrapSnapshot } from './FirestoreService';

export const PAGE_SIZE = 8;

export default class NotificationsService {
  static instance = new NotificationsService();

  converter = converterWithId<Notification>();

  collection(userId: string) {
    return firebase
      .firestore()
      .collection('profiles')
      .doc(userId)
      .collection('notifications')
      .withConverter(this.converter);
  }

  incomingNotifications$(
    { userId }: { userId: string },
    callback: (result: (Notification & Id)[]) => void
  ) {
    return this.collection(userId)
      .orderBy('timestamp', 'desc')
      .limit(PAGE_SIZE)
      .onSnapshot((sn) => callback(unwrapSnapshot(sn)));
  }

  loadOlder({ userId, olderThan }: { userId: string; olderThan: number }) {
    return this.collection(userId)
      .orderBy('timestamp', 'desc')
      .startAfter(olderThan)
      .limit(PAGE_SIZE)
      .get();
  }
}
