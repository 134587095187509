import { createMuiTheme, SimplePaletteColorOptions } from '@material-ui/core';
import overrides from './overrides';
import prepareTheme from './prepareTheme';
import props from './props';

interface BGradients {
  brand: string;
  brandCircle: string;
  action: string;
  actionLarge: string;
  button: string;
  primary: string;
  error: string;
}

interface BContrastText {
  contrastText: string;
}

interface BTypography {
  fontFamilyTitle: string;
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    gradients: BGradients;
  }

  interface ThemeOptions {
    gradients?: BGradients;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    brand: SimplePaletteColorOptions;
    like: string;
    superLike: string;
    google: string;
    facebook: string;
    divider96: string;
    background96: string;
  }

  interface PaletteOptions {
    brand: SimplePaletteColorOptions;
    like: string;
    superLike: string;
    google: string;
    facebook: string;
    divider96: string;
    background96: string;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    fontFamilyTitle: string;
  }

  interface TypographyOptions {
    fontFamilyTitle?: string;
  }
}

const theme = prepareTheme()

export default createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    info: {
      main: theme.palette.primary.main,
    },
    success: {
      main: theme.palette.primary.main,
    },
  },
  props: props(),
  overrides: overrides(theme),
});
