import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../components/BRouter';

export const useSearch = () => {
  const history = useHistory();

  return useCallback(
    (query: string) => {
      const q = query.trim() ? query : undefined;

      history.push({
        pathname: ROUTES.getInspired,
        search: q ? `?q=${encodeURIComponent(q)}` : undefined,
      });
    },
    [history]
  );
};

export default useSearch;
