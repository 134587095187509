import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Videocam from '@material-ui/icons/Videocam';
import clsx from 'clsx';
import React from 'react';
import upload from '../../../assets/upload.svg';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { useAppSelector } from '../../../store';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  fileUrl: string | null;
  mediaType: string | null;
  progress?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.gradients.actionLarge,
    position: 'relative',
  },
  uploadLabel: {
    padding: 0,
    textTransform: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,
    minHeight: 180,
    '& img': {
      display: 'block',
    },
    '& input': {
      display: 'none',
    },
  },
  uploadLabelInner: {
    color: theme.palette.brand.dark,
    flexDirection: 'column',
  },
  disabled: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '5em',
    height: '5em',
  },
  progress: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
  uploadIcon: {
    width: '50%',
    maxWidth: 140,
    marginBottom: theme.spacing(1),
  },
  uploadPreview: {
    maxWidth: '100%',
    maxHeight: 300,
  },
}));

const FileUpload: React.FC<Props> = ({
  fileUrl,
  mediaType,
  progress,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const content = useLocalizedContent('create_post');
  const inProgress = progress !== undefined;

  const {
    isChallenge,
    rootPost,
    editPost,
    deleteEditPostMedia: deleteOld,
  } = useAppSelector(({ posts }) => posts);

  const { mediaUrl: existingMediaUrl, mediaType: existingMediaType } =
    editPost || {};

  const finalMediaType = mediaType || existingMediaType;
  const finalFileUrl = fileUrl || existingMediaUrl;

  return (
    <Box
      className={classes.root}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Button
        classes={{
          root: classes.uploadLabel,
          label: classes.uploadLabelInner,
        }}
        className={clsx(inProgress && classes.disabled)}
        component="label"
        title={content.upload_a_photo_or_video}
        fullWidth
      >
        {(!deleteOld && finalFileUrl) || (!!deleteOld && !!fileUrl) ? (
          (() => {
            if (finalMediaType?.startsWith('image/')) {
              return (
                <img
                  className={classes.uploadPreview}
                  src={finalFileUrl || undefined}
                  alt="File"
                />
              );
            } else if (mediaType?.startsWith('video/')) {
              return <Videocam className={classes.icon} />;
            }
          })()
        ) : (
          <>
            <img className={classes.uploadIcon} alt="Upload" src={upload} />
            <Typography>
              {isChallenge && !rootPost
                ? content.upload_your_challenge_video
                : editPost?.mediaUrl || fileUrl
                ? content.edit_photo_video
                : content.upload_a_photo_or_video}
            </Typography>
          </>
        )}
        <input
          type="file"
          accept={isChallenge && !rootPost ? 'video/*' : 'image/*,video/*'}
          disabled={inProgress}
          {...rest}
        />
        <CircularProgress
          className={classes.progress}
          variant={progress === 0 ? 'indeterminate' : 'static'}
          value={(progress ?? 0) * 100}
        />
      </Button>
      {children}
    </Box>
  );
};

export default FileUpload;
