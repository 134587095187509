import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { actions as authActions } from '../slices/auth';
import { useAppDispatch } from '../store';
import useUser from './useUser';

const useEmailVerified = (check: boolean = true) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const user = useUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval: number;

    if (!check) {
      return;
    }

    if (!user?.emailVerified) {
      interval = window.setInterval(async () => {
        await firebase.auth().currentUser?.reload();
        if (firebase.auth().currentUser?.emailVerified) {
          await firebase.auth().currentUser?.getIdToken(true);
          setEmailVerified(true);
          dispatch(authActions.updateUser({ emailVerified: true }));
          window.clearInterval(interval);
        }
      }, 10000);
    }

    return () => window.clearInterval(interval);
  }, [check, dispatch, user]);

  return emailVerified || !!user?.emailVerified;
};

export default useEmailVerified;
