import { Button, DialogProps } from '@material-ui/core';
import React from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import BRichText from '../../utils/BRichText';
import BDialog from '../BDialog';

const TermsAndConditionsDialog: React.FC<DialogProps> = ({
  onClose,
  ...rest
}) => {
  const content = useLocalizedContent('settings');

  if (content.__empty) {
    return null;
  }

  return (
    <BDialog
      id="termsAndConditions"
      title={content.terms_of_use_title}
      maxWidth="md"
      onClose={onClose}
      actions={
        <Button color="primary" onClick={() => onClose?.({}, 'escapeKeyDown')}>
          {content.tc_modal_button_text}
        </Button>
      }
      {...rest}
    >
      <BRichText render={content.terms_of_use} />
    </BDialog>
  );
};

export default TermsAndConditionsDialog;
