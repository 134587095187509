import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';
import MediaService from '../../services/MediaService';
import defaultUser from '../../assets/default-user.svg';
import { useAppSelector } from '../../store';

interface Props extends AvatarProps {
  userId?: string;
}

const useStyles = makeStyles({
  fallback: {
    width: '100%',
    height: '100%',
  },
});

const BUserAvatar: React.FC<Props> = memo(({ userId, src, ...rest }) => {
  const classes = useStyles();

  const refresh = useAppSelector(
    ({ profiles: { profileImageRefresh }, auth: { user } }) =>
      user?.uid === userId ? profileImageRefresh : undefined
  );

  return (
    <Avatar
      src={
        src ??
        (userId
          ? `${MediaService.instance.getProfileImageUrl(userId)}${
              refresh ? `&refresh=${refresh}` : ''
            }`
          : undefined)
      }
      {...rest}
    >
      <img alt={rest.alt} className={classes.fallback} src={defaultUser} />
    </Avatar>
  );
});

export default BUserAvatar;
