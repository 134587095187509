import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { PASSWORD_MIN_LENGTH } from '../../../services/AuthService';
import { actions as loginActions } from '../../../slices/login';
import { actions as authActions } from '../../../slices/auth';
import { actions as rootActions } from '../../../slices/root';
import { useAppDispatch, useAppSelector } from '../../../store';
import { unwrapFirebaseError } from '../../../utils/format';
import { ROUTES } from '../../BRouter';
import BTextField from '../../inputs/BTextField';

interface Props {
  email: string;
  onEmailChange: (value: string) => void;
  onPasswordForgot: () => void;
  onTermsOfUse: () => void;
}

const useStyles = makeStyles((theme) => ({
  facebookButton: {
    background: theme.palette.facebook,
    '&:hover': {
      background: theme.palette.facebook,
    },
  },
  googleButton: {
    background: theme.palette.google,
    '&:hover': {
      background: theme.palette.google,
    },
  },
  button: {
    margin: theme.spacing(0.5, 0, 1.5),
  },
  or: {
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 0),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

const LoginForm: React.FC<Props> = ({
  email,
  onEmailChange,
  onPasswordForgot,
  onTermsOfUse,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const loginContent = useLocalizedContent('login');
  const registrationContent = useLocalizedContent('registration');
  const errorMessages = useLocalizedContent('error-messages');

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [password, setPassword] = useState('');

  const authModal = useAppSelector(({ auth: { authModal } }) => authModal!);

  const handleAuthError = (error: Error) =>
    dispatch(
      rootActions.showInfo({
        text: unwrapFirebaseError(error, errorMessages),
        severity: 'error',
      })
    );

  const onLoginSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (authModal === 'login') {
        await dispatch(loginActions.login({ username: email, password }));
      } else {
        if (!termsAccepted) {
          dispatch(
            rootActions.showInfo({
              text: registrationContent.accept_terms_error,
              severity: 'error',
            })
          );
          return;
        }

        await dispatch(
          authActions.createEmailAccountSubmit({ email, password })
        );

        history.push(ROUTES.onboarding);
      }
    } catch (error) {
      handleAuthError(error);
    }
  };

  const onSigninWithGoogleClick = async () => {
    try {
      await dispatch(loginActions.signinWithGoogle());
    } catch (error) {
      handleAuthError(error);
    }
  };

  const onSigninWithFacebookClick = async () => {
    try {
      await dispatch(loginActions.signinWithFacebook());
    } catch (error) {
      handleAuthError(error);
    }
  };

  const toggleAuthModal = () => dispatch(authActions.toggleAuthModal());

  const onTermsOfUseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onTermsOfUse();
  };

  if (loginContent.__empty || registrationContent.__empty) {
    return null;
  }

  return (
    <>
      {/* <Button
        color="primary"
        classes={{
          containedPrimary: classes.facebookButton,
        }}
        className={classes.button}
        onClick={onSigninWithFacebookClick}
        fullWidth
      >
        {authModal === 'login'
          ? loginContent.login_with_facebook
          : registrationContent.facebook_signup_button}
      </Button> */}
      <Button
        color="primary"
        classes={{
          contained: classes.googleButton,
        }}
        onClick={onSigninWithGoogleClick}
        fullWidth
      >
        {authModal === 'login'
          ? loginContent.login_with_google_
          : registrationContent.google_plus_signup_button}
      </Button>
      <Typography className={classes.or} align="center">
        {loginContent.or_text}
      </Typography>
      <form action="" onSubmit={onLoginSubmit}>
        <BTextField
          className={classes.textField}
          type="email"
          placeholder={loginContent.email_placeholder}
          label={loginContent.email_text}
          onChange={(e) => onEmailChange(e.currentTarget.value)}
          required
        />
        <BTextField
          placeholder={loginContent.password_placeholder}
          type="password"
          label={loginContent.password_text}
          onChange={(e) => setPassword(e.currentTarget.value)}
          inputProps={{
            minLength: PASSWORD_MIN_LENGTH,
          }}
          required
        />
        {authModal === 'signup' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.currentTarget.checked)}
              />
            }
            label={
              <Typography>
                {registrationContent.tc_not_link}{' '}
                <a href="#terms" onClick={onTermsOfUseClick}>
                  {registrationContent.tc_link}
                </a>
              </Typography>
            }
          />
        )}
        <Button
          type="submit"
          className={classes.button}
          color="primary"
          fullWidth
        >
          {authModal === 'login'
            ? loginContent.login_button_text
            : registrationContent.submit}
        </Button>
      </form>
      {authModal === 'login' && (
        <Button
          className={classes.button}
          variant="text"
          onClick={onPasswordForgot}
          size="small"
          fullWidth
        >
          {loginContent.forgot_password_button_text}
        </Button>
      )}
      <Button variant="text" onClick={toggleAuthModal} size="small" fullWidth>
        {authModal === 'login'
          ? loginContent.dont_have_an_account
          : registrationContent.already_a_member_button}
      </Button>
    </>
  );
};

export default LoginForm;
