import React, { memo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '../BRouter';

interface Props {
  className?: string;
  userId?: string;
}

const BProfileLink: React.FC<Props> = memo(({ userId, ...rest }) => (
  <Link {...rest} to={userId ? generatePath(ROUTES.user, { userId }) : ''} />
));

export default BProfileLink;
