import { Cause } from 'bee';
import FirestoreService from './FirestoreService';


export default class CausesService extends FirestoreService<Cause> {
  static instance = new CausesService();

  constructor() {
    super('causes');
  }

  update(id: string, data: Cause) {
    return this.doc(id).set({ id: '', ...data }, { merge: true });
  }

  async getTranslation(id: string, locale: string) {
    const cause = await CausesService.instance.get(id);
    if (!cause) {
      return;
    }
    let translationDoc = await CausesService.instance
      .doc(id)
      .collection('translations')
      .doc(locale)
      .get();
    if (!translationDoc.exists) {
      return cause;
    }
    return {
      ...cause,
      ...translationDoc.data(),
    };
  }
}
