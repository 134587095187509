import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as donationActions } from '../slices/donations';
import { bAnalytics, Events } from '../utils/analytics';

export default (...params: Parameters<typeof donationActions.startDonation>) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    bAnalytics.track(Events.ClickedDonate, params);
    dispatch(donationActions.startDonation(...params));
  }, [dispatch, params]);
}