import { Ad } from 'bee';
import FirestoreService from './FirestoreService';

export default class AdsService extends FirestoreService<Ad> {
  static instance = new AdsService()

  constructor() {
    super('ads');
  }
}
