import { Id, Post } from 'bee';
import React, { memo } from 'react';
import useIntersect from '../../../../hooks/useIntersect';
import usePostData from '../../../../hooks/usePostData';
import BPostCard, { BPostCardProps } from './BPostCard';

interface BPostCardListItemProps extends Partial<BPostCardProps> {
  post: Post & Id;
}

const BPostCardListItem: React.FC<BPostCardListItemProps> = memo(
  ({ post: _post, embedded, disableEmbed }) => {
    const [ref, isOnScreen] = useIntersect({ rootMargin: '100px 0px' });
    const { post, comments, descendants } = usePostData(_post.id, isOnScreen);

    return (
      <BPostCard
        ref={ref}
        post={post ?? _post}
        comments={comments}
        embedded={embedded}
        descendants={descendants}
        disableEmbed={disableEmbed}
      />
    );
  }
);

export default BPostCardListItem;
