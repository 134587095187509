import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { Geoloc, Id, LatLngBounds, Post } from 'bee';
import AlgoliaService from '../services/AlgoliaService';
import PostsService, { CreatePostData } from '../services/PostsService';
import { AppThunkApiConfig } from '../store';
import { bAnalytics, Events } from '../utils/analytics';

interface State {
  posts: (Post & Id)[] | null;
  lastVisible?: number;
  endReached: boolean;
  sharePost: (Post & Id) | null;
  showCreatePost: boolean;
  isChallenge?: boolean;
  rootPost?: string;
  editPost?: (Post & Id);
  deleteEditPostMedia?: boolean;
}

interface EditPostPayload {
  post: (Post & Id);
  show: boolean;
}

const DEFAULT_PAGE_SIZE = 50;

const submitPost = createAsyncThunk<Post & Id, CreatePostData>(
  'posts/submitPost',
  async (payload) => {
    const post = await PostsService.instance.create(payload);
    bAnalytics.track(Events.CreatedPost, payload);
    return post;
  }
);

const loadPosts = createAsyncThunk<
  (Post & Id)[],
  {
    limit?: number;
    specificUserId?: string;
    replacePosts?: boolean;
  },
  AppThunkApiConfig
>('posts/loadPosts', ({ replacePosts = false, ...query }, { getState }) => {
  const { lastVisible } = getState().posts;

  return PostsService.instance.getMostRecentPosts({
    startAfter: replacePosts ? undefined : lastVisible,
    limit: DEFAULT_PAGE_SIZE,
    ...query,
  });
});

const deletePost = createAsyncThunk<
  void,
  {
    postId: string;
  }
>('posts/deletePost', async ({ postId }) =>
  PostsService.instance.delete(postId)
);

const searchPosts = createAsyncThunk<
  (Post & Id)[],
  {
    query?: string,
    bounds?: LatLngBounds,
    coords?: Geoloc,
  },
  AppThunkApiConfig
>('posts/searchPosts', async ({ query, coords, bounds }, { dispatch }) => {
  bAnalytics.track(Events.Searched, {
    searchTerm: query,
  });

  if ((query && query.trim()) || coords || bounds) {
    let opts: any = {};

    if (coords) {
      opts.aroundLatLng = `${coords.lat}, ${coords.lng}`;
      opts.aroundRadius = 1000;
    }
    else if (bounds) {
      opts.insideBoundingBox = [[
        bounds.north,
        bounds.west,
        bounds.south,
        bounds.east,
      ]];
    }

    const hits = (await AlgoliaService.posts.search(
      query || "",
      opts,
    )).hits;

    return hits.map(({ objectID, ...hit }) => ({
      ...hit,
      id: objectID,
    }));
  }

  return unwrapResult(await dispatch(loadPosts({ replacePosts: true })));
});

const INITIAL_STATE: State = {
  posts: [],
  endReached: false,
  sharePost: null,
  showCreatePost: false,
  isChallenge: false,
  rootPost: undefined,
};

const slice = createSlice({
  name: 'posts',
  initialState: INITIAL_STATE,
  reducers: {
    prependPosts: (state, action: PayloadAction<(Post & Id)[]>) => {
      const newPosts = action.payload.filter(post => {
        return !(state.posts || []).find(_oldPost => _oldPost.id === post.id)
      });
      state.posts = [...newPosts, ...(state.posts ?? [])];
    },
    setSharePost: (state, action: PayloadAction<(Post & Id) | null>) => {
      state.sharePost = action.payload;
    },
    purgePosts: (state) => {
      state.posts = null;
      state.sharePost = null;
      delete state.lastVisible;
    },
    setDeleteEditPostMedia: (state, action: PayloadAction<boolean>) => {
      state.deleteEditPostMedia = action.payload;
    },
    setShowCreatePost: (state, action: PayloadAction<boolean>) => {
      state.showCreatePost = action.payload;
      state.rootPost = undefined;
      state.isChallenge = false;
      state.editPost = undefined;
      state.deleteEditPostMedia = undefined;
    },
    setShowEditPost: (state, action: PayloadAction<EditPostPayload>) => {
      state.showCreatePost = action.payload.show;
      state.rootPost = undefined;
      state.isChallenge = false;
      state.editPost = action.payload.post;
      state.deleteEditPostMedia = undefined;
    },
    setShowCreateChallenge: (state, action: PayloadAction<boolean>) => {
      state.showCreatePost = action.payload;
      state.rootPost = undefined;
      state.isChallenge = true;
      state.editPost = undefined;
      state.deleteEditPostMedia = undefined;
    },
    setShowAcceptChallenge: (state, action: PayloadAction<string | false>) => {
      state.showCreatePost = !!action.payload;
      state.rootPost = !!action.payload ? action.payload : undefined;
      state.isChallenge = true;
      state.editPost = undefined;
      state.deleteEditPostMedia = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitPost.fulfilled, (state, action) => {
      state.sharePost = action.payload;
    });

    builder.addCase(loadPosts.fulfilled, (state, action) => {
      const { replacePosts } = action.meta.arg;
      const posts = action.payload;
      const lastVisible = posts[posts.length - 1]?.postedTimestamp;

      state.endReached =
        posts.length < (action.meta.arg.limit ?? DEFAULT_PAGE_SIZE);

      if (lastVisible) {
        state.lastVisible = lastVisible;
      }

      if (replacePosts || !state.posts) {
        state.posts = posts;
      } else {
        state.posts = [...state.posts, ...posts];
      }
    });

    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.posts = state.posts!.filter((p) => p.id !== action.meta.arg.postId);
    });

    builder.addCase(searchPosts.fulfilled, (state, action) => {
      state.posts = action.payload;
    });
  },
});

export const actions = {
  ...slice.actions,
  submitPost,
  loadPosts,
  deletePost,
  searchPosts,
};

export default slice.reducer;
