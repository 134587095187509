/* eslint-disable import/first */
const browserUpdate = require('browser-update');
browserUpdate({
  required: {
    e: 11,
    f: 55,
    o: 50,
    s: 8,
    c: 50,
  },
  test: true,
  reminder: 0,
  insecure: true,
  unsupported: true,
  mobile: false,
  api: 2020.06,
  nomessage: true,
  onshow: function (info: any) {
    if (info.is_below_required) {
      console.log('@@ Outdated browser Redirecting...', info);
      window.location.href = 'https://browser-update.org/update.html';
    }
  },
});

browserUpdate({
  required: {
    e: -4,
    f: -3,
    o: -3,
    s: -1,
    c: -3,
  },
  insecure: true,
  unsupported: true,
  style: 'corner',
  api: 2020.06,
});

const mapsScript = document.createElement(`script`);
mapsScript.type = 'text/javascript';
mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`;
mapsScript.id = 'google-maps-script';
mapsScript.onload = () => {
  mapsScript.setAttribute('data-loaded', 'true');
};
document.body.append(mapsScript);

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'intersection-observer';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import BRouter from './components/BRouter';
import store from './store';
import theme from './theme';
import { firebaseConfig } from './utils/firebase';

firebase.initializeApp(firebaseConfig);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     include: [
//       /^B/,
//     ],
//     trackAllPureComponents: true,
//   });
// }

render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BRouter />
      </ThemeProvider>
    </Provider>
  </Elements>,
  document.getElementById('app')
);
