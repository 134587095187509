import useProfileMeta from './useProfileMeta';
import useUser from './useUser';

const useIsAdmin = () => {
  const user = useUser();
  const meta = useProfileMeta(user?.uid);
  return !!meta?.admin;
};

export default useIsAdmin;
