import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Business from '@material-ui/icons/Business';
import { Profile } from 'bee';
import React from 'react';

interface Props {
  profile: Pick<Profile, 'isOrganization' | 'website'>;
}

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    fill: theme.palette.primary.dark,
  },
}));

const BProfileIcon: React.FC<Props> = ({
  profile: { isOrganization, website },
}) => {
  const classes = useStyles();

  if (!isOrganization) {
    return null;
  }

  if (website) {
    return (
      <Tooltip
        title={`${website.slice(0, 20)}${website.length > 20 ? '...' : ''}`}
        arrow
      >
        <a href={website} target="_blank" rel="noopener noreferrer">
          <Business titleAccess="Organization" className={classes.headerIcon} />
        </a>
      </Tooltip>
    );
  }

  return <Business titleAccess="Organization" className={classes.headerIcon} />;
};

export default BProfileIcon;
