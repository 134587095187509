import { Box, Chip, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseRounded, LocationOn } from '@material-ui/icons';
import { Id, Post, SearchProfile } from 'bee';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useIntersect from '../../../hooks/useIntersect';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useSearchBounds from '../../../hooks/useSearchBounds';
import useSearchCoords from '../../../hooks/useSearchCoords';
import useSearchLocationLabel from '../../../hooks/useSearchLocationLabel';
import useSearchQuery from '../../../hooks/useSearchQuery';
import AlgoliaService from '../../../services/AlgoliaService';
import PostsService from '../../../services/PostsService';
import { actions as adsActions } from '../../../slices/ads';
import { actions as postActions } from '../../../slices/posts';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ROUTES } from '../../BRouter';
import BMiniProfile from '../../utils/BMiniProfile';
import { BMapPromo } from './BMapPromo';
import BPostCardInfiniteScroller from './BPostCardInfiniteScroller';
import { BFeaturedProfiles } from './BFeaturedProfiles/BFeaturedProfiles';
import { BMap } from './BMap';

interface Props {
  userId?: string;
  showCreatePostButton?: boolean;
  showFeaturedProfiles?: boolean;
  showMap?: boolean;
  hideSidebar?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  posts: {
    order: 1,
    [theme.breakpoints.only('xs')]: {
      order: 2,
    },
  },
  sidebar: {
    order: 2,
    [theme.breakpoints.only('xs')]: {
      order: 1,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'sticky',
      top: 48,
    },
  },
  scroller: {
    padding: theme.spacing(0, 0.5),
  },
  algolia: {
    display: 'inline-block',
    width: 100,
  },
}));

const EMPTY_POSTS: (Post & Id)[] = [];

const BPostScroller: React.FC<Props> = ({
  userId,
  showFeaturedProfiles = false,
  showMap = false,
  hideSidebar = false,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const searchQuery = useSearchQuery();
  const searchCoords = useSearchCoords();
  const searchBounds = useSearchBounds();
  const searchLocationLabel = useSearchLocationLabel();

  const content = useLocalizedContent('my_posts');
  const settingsContent = useLocalizedContent('settings');

  const [ref, isIntersecting] = useIntersect();
  const [firstPageLoaded, setFirstPageLoaded] = useState(false);
  const [searchProfiles, setSearchProfiles] = useState<(SearchProfile & Id)[]>(
    []
  );
  const shouldSubscribe =
    firstPageLoaded &&
    !(searchQuery || searchBounds || searchCoords) &&
    isIntersecting;
  const { posts, endReached, ads } = useAppSelector(
    ({ posts: { posts, endReached }, ads: { ads } }) => ({
      posts,
      endReached,
      ads,
    }),
    shallowEqual
  );
  const firstTimestamp = posts?.[0]?.postedTimestamp;

  const loadPosts = useCallback(
    async (replacePosts = false) => {
      dispatch(
        postActions.loadPosts({
          specificUserId: userId,
          replacePosts,
          limit: 150,
        })
      );
    },
    [dispatch, userId]
  );

  useEffect(() => {
    (async () => {
      if (!searchQuery) {
        setSearchProfiles([]);
      }

      if (!searchCoords && !searchBounds && searchQuery) {
        setSearchProfiles(
          (
            await AlgoliaService.profiles.search(searchQuery)
          ).hits.map(({ objectID, ...hit }) => ({ ...hit, id: objectID }))
        );
      }

      if (searchQuery || searchBounds || searchCoords) {
        dispatch(
          postActions.searchPosts({
            query: searchQuery || undefined,
            coords: searchCoords || undefined,
            bounds: searchBounds || undefined,
          })
        );
      }
    })();
  }, [dispatch, searchBounds, searchCoords, searchQuery]);

  useEffect(() => {
    if (!searchQuery && !searchBounds && !searchCoords) {
      (async () => {
        await loadPosts();

        window.setTimeout(() => {
          setFirstPageLoaded(true);
        }, 2000);
      })();
    }

    dispatch(adsActions.getAds());

    return () => {
      dispatch(postActions.purgePosts());
    };
  }, [dispatch, searchQuery, loadPosts, searchBounds, searchCoords]);

  useEffect(() => {
    const ts = firstTimestamp ?? Date.now();

    if (!shouldSubscribe) {
      return;
    }

    return PostsService.instance.incomingPosts$(
      {
        since: ts,
        specificUserId: userId,
      },
      (ps) => {
        if (ps.length > 0) {
          dispatch(postActions.prependPosts(ps));
        }
      }
    );
  }, [dispatch, firstTimestamp, shouldSubscribe, userId]);

  if (!posts) {
    return null;
  }

  return (
    <>
      {showMap && <BMap posts={posts} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={hideSidebar ? 12 : 8} className={classes.posts}>
          <div ref={ref} />
          {/* {showCreatePostButton && (
            <Hidden smUp>
              <BPostChallenge />
            </Hidden>
          )} */}
          {/* {showCreatePostButton && <BWhatsOnYourMind />} */}
          {showFeaturedProfiles && <BFeaturedProfiles />}
          {!!(searchQuery || searchCoords || searchBounds) && (
            <Box paddingBottom={2} paddingTop={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                justify="center"
              >
                <Grid item>
                  <Typography>{settingsContent.search_by_algolia}</Typography>
                </Grid>
                <Grid item>
                  <img
                    src="/media/algolia.png"
                    alt="Algolia"
                    className={classes.algolia}
                  />
                </Grid>
              </Grid>
              <Box marginTop={2} marginX={-0.5}>
                <Divider />
              </Box>
            </Box>
          )}
          {!!searchLocationLabel && !!(searchBounds || searchCoords) && (
            <Box py={2}>
              <Chip
                icon={<LocationOn />}
                label={searchLocationLabel}
                onDelete={() => {
                  if (searchQuery) {
                    history.push(`${ROUTES.getInspired}?q=${searchQuery}`);
                  } else {
                    history.push(ROUTES.getInspired);
                  }
                }}
                deleteIcon={<CloseRounded />}
              />
            </Box>
          )}
          {!!searchProfiles.length && (
            <Grid className={classes.scroller} container spacing={2}>
              {searchProfiles.map((sp) => (
                <Grid item xs={12} sm={6}>
                  <BMiniProfile key={sp.id} profile={sp} />
                </Grid>
              ))}
            </Grid>
          )}
          {!!searchProfiles.length && !!posts.length && (
            <Box marginTop={2} marginX={-0.5}>
              <Divider />
            </Box>
          )}
          {posts && !searchProfiles.length && !posts.length && (
            <Typography color="textSecondary" align="center">
              {content.no_search_results}
            </Typography>
          )}
          <BPostCardInfiniteScroller
            className={classes.scroller}
            posts={posts ?? EMPTY_POSTS}
            nextPage={loadPosts}
            shouldLoad={
              firstPageLoaded &&
              !endReached &&
              !(!!searchQuery || !!searchCoords || !!searchBounds)
            }
          />
        </Grid>
        {hideSidebar ? null : (
          <Grid item xs={12} sm={4} className={classes.sidebar}>
            {/* {showCreatePostButton && (
            <Hidden xsDown>
              <BPostChallenge />
            </Hidden>
          )} */}
            <BMapPromo />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BPostScroller;
