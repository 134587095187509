import { Box, Button, IconButton, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutlined } from '@material-ui/icons';
import { GeoData, Geoloc, MediaStatus } from 'bee';
import clsx from 'clsx';
import firebase from 'firebase/app';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete/build';
import useIsAdmin from '../../../hooks/useIsAdmin';
import useLocale from '../../../hooks/useLocale';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useUser from '../../../hooks/useUser';
import { generateId } from '../../../services/FirestoreService';
import MediaService from '../../../services/MediaService';
import PostsService, { CreatePostData } from '../../../services/PostsService';
import { actions as postActions } from '../../../slices/posts';
import { actions as rootActions } from '../../../slices/root';
import { useAppDispatch, useAppSelector } from '../../../store';
import BUserAvatar from '../../utils/BUserAvatar';
import CauseSelect from './CauseSelect';
import FileUpload from './FileUpload';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'flex',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider96}`,
  },
  inputInset: {
    padding: theme.spacing(0.5, 1, 0.5, 6),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  location: {
    width: '100%',
  },
}));

interface BNewPostFormProps {
  onPostSuccess: () => void;
}

const BNewPostForm: React.FC<BNewPostFormProps> = ({ onPostSuccess }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const rootPost = useAppSelector(({ posts: { rootPost } }) => rootPost);
  const { editPost, deleteEditPostMedia: deleteOldMedia } = useAppSelector(
    ({ posts }) => posts
  );

  const { description: existingDescription, location: existingLocation } =
    editPost || {};

  const isChallengeAccepted = !!rootPost;

  const isAdmin = useIsAdmin();

  const user = useUser();
  const [postDescription, setPostDescription] = useState(
    editPost ? editPost.description : isChallengeAccepted ? '✅' : ''
  );
  const [location, setLocation] = useState(editPost?.location || '');
  const [geodata, setGeodata] = useState<GeoData>();
  const [coordinates, setCoordinates] = useState<Geoloc>();
  const [fileLocalUrl, setFileLocalUrl] = useState<string | null>(null);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [fileMediaType, setFileMediaType] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>();
  const [postId, setPostId] = useState(generateId());
  const [cause, setCause] = useState<string>();
  const inProgress = progress !== undefined;

  const storageRef = useMemo(
    () => firebase.storage().ref(`/posts/media/${postId}`),
    [postId]
  );

  const locale = useLocale();

  const content = useLocalizedContent('create_post');

  const isChallenge = useAppSelector(
    ({ posts: { isChallenge } }) => !!isChallenge
  );

  useEffect(() => {
    if (existingLocation) {
      setLocation(existingLocation);
    } else {
      setLocation('');
    }
    if (existingDescription) {
      setPostDescription(existingDescription);
    } else {
      setPostDescription('');
    }
  }, [existingDescription, existingLocation]);

  const resetForm = (onlyMedia = false) => {
    const resetEverything = !onlyMedia;

    if (fileLocalUrl) {
      window.URL.revokeObjectURL(fileLocalUrl);
    }

    if (resetEverything) {
      setPostDescription('');
      setLocation('');
      setPostId(generateId());
    }

    dispatch(postActions.setDeleteEditPostMedia(true));
    setFileLocalUrl(null);
    setFileToUpload(null);
  };

  const onChangeUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];

    if (!file) {
      return;
    }

    if (fileLocalUrl) {
      window.URL.revokeObjectURL(fileLocalUrl);
    }

    setFileToUpload(file);
    setFileLocalUrl(window.URL.createObjectURL(file));
    setFileMediaType(file.type);
  };

  const onPostDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setPostDescription(e.currentTarget.value);
    },
    []
  );

  const onLocationChange = useCallback(async (d: any) => {
    setLocation(d.label);
    const geocoded = await geocodeByAddress(d.label);
    const _geodata: GeoData = {
      place: JSON.parse(JSON.stringify(d)),
      geocoded: JSON.parse(JSON.stringify(geocoded)),
    };
    if (geocoded.length) {
      const firstGeocoded = geocoded[0];
      const latlng = await getLatLng(firstGeocoded);
      setCoordinates(latlng);
      if (!!firstGeocoded.geometry.bounds) {
        _geodata.bounds = firstGeocoded.geometry.bounds.toJSON();
      }
    }
    setGeodata(_geodata);
  }, []);

  const onDeleteMedia = () => resetForm(true);

  const onSubmitCreatePost = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (
      location?.length > 0 &&
      !!coordinates &&
      !!geodata &&
      (isChallengeAccepted || postDescription?.length > 0) &&
      (!isChallenge || (isChallenge && (!!rootPost || !!fileToUpload)))
    ) {
      setProgress(0);

      let mediaUrl = editPost?.mediaUrl || null;
      let mediaType = editPost?.mediaType || null;
      let mediaStatus: MediaStatus | null = editPost?.mediaStatus || null;

      if (deleteOldMedia) {
        mediaUrl = null;
        mediaType = null;
        mediaStatus = null;
      }

      try {
        if (fileToUpload) {
          if (fileMediaType?.includes('image/')) {
            mediaType = fileMediaType;
            mediaUrl = await MediaService.instance.uploadImage(
              storageRef.child(
                `${generateId()}${MediaService.instance.extractExtension(
                  fileToUpload.name
                )}`
              ),
              fileToUpload,
              setProgress
            );
          } else if (fileMediaType?.includes('video/')) {
            await MediaService.instance.uploadVideo(
              postId,
              fileToUpload,
              setProgress
            );
            mediaType = fileMediaType;
            mediaStatus = 'processing';
            mediaUrl = '';
          } else {
            return;
          }
        }

        const u = firebase.auth().currentUser!;

        const post: CreatePostData = {
          ...(editPost || {}),
          userId: u.uid,
          description: isChallengeAccepted
            ? postDescription
              ? postDescription
              : '✅'
            : postDescription,
          postId: editPost ? editPost.id : postId,
          _geoloc: coordinates,
          geodata,
          location,
          mediaUrl,
          mediaType,
          mediaStatus,
          locale,
        };

        if (isChallenge) {
          post.isChallenge = isChallenge;
        }

        if (cause) {
          post.cause = cause;
        }

        if (rootPost) {
          post.rootPost = rootPost;
        }

        await dispatch(postActions.submitPost(post));

        if (rootPost) {
          await PostsService.instance.unsaveChallenge(rootPost);
        }

        onPostSuccess();
        resetForm();
      } catch (error) {
        console.error(error);
        dispatch(
          rootActions.showInfo({
            text: error.message,
            severity: 'error',
          })
        );
      } finally {
        setProgress(undefined);
      }
    } else {
      dispatch(
        rootActions.showInfo({
          text: content.must_enter_title_and_description_message,
          severity: 'error',
        })
      );
    }
  };

  return (
    <>
      <FileUpload
        fileUrl={fileLocalUrl}
        mediaType={fileMediaType}
        progress={progress}
        onChange={onChangeUploadFile}
      >
        {(fileLocalUrl ||
          editPost?.mediaUrl ||
          (!!deleteOldMedia && !!fileLocalUrl)) && (
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={onDeleteMedia}
            disabled={inProgress}
            title="Remove media"
          >
            <DeleteOutlined />
          </IconButton>
        )}
      </FileUpload>
      <form className="BNewPostForm" onSubmit={onSubmitCreatePost}>
        <div className={classes.input}>
          <BUserAvatar
            className={classes.avatar}
            userId={user?.uid}
            alt="You"
          />
          <Input
            rows={1}
            rowsMax={10}
            multiline
            placeholder={
              isChallenge && !isChallengeAccepted
                ? content.what_s_your_challenge
                : isChallengeAccepted
                ? content.challenge_accepted
                : content.whats_on_your_mind
            }
            onChange={onPostDescriptionChange}
            value={postDescription}
            disabled={inProgress}
            inputProps={{
              maxLength: 500,
            }}
            autoFocus
          />
        </div>
        <div className={clsx(classes.input, classes.inputInset)}>
          <GooglePlacesAutocomplete
            debounce={150}
            minLengthAutocomplete={2}
            selectProps={{
              placeholder: content.where_are_you,
              onChange: onLocationChange,
              className: classes.location,
            }}
          />
        </div>
        {isAdmin && (
          <div className={clsx(classes.input, classes.inputInset)}>
            <CauseSelect
              className={classes.location}
              onChange={(selected: any) => {
                setCause(selected?.value || undefined);
              }}
            />
          </div>
        )}
        <Box padding={1.5}>
          <Button type="submit" color="primary" disabled={inProgress} fullWidth>
            {isChallenge ? content.lets_do_it : content.submit_post_button_text}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default BNewPostForm;
