import {
  Button,
  Menu,
  MenuItem,
  MenuProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuRounded from '@material-ui/icons/MenuRounded';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import useEmailVerified from '../../../hooks/useEmailVerified';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useProfile from '../../../hooks/useProfile';
import { actions as loginActions } from '../../../slices/login';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ROUTES } from '../../BRouter';
import BUserAvatar from '../../utils/BUserAvatar';

interface Props {
  className?: string;
  onLogin: () => void;
  onSignup: () => void;
  onCreatePost: () => void;
  onLocaleChange: () => void;
  isBusiness?: boolean;
}

const DROPDOWN_MENU_ID = 'dropdown-menu';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    fill: theme.palette.primary.main,
    width: '1.5em',
    height: '1.5em',
  },
}));

const BDropDownMenu: React.FC<Props> = ({
  className,
  onSignup,
  onLogin,
  onCreatePost,
  onLocaleChange,
  isBusiness = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const content = useLocalizedContent('nav');
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const [anchor, setAnchor] = useState<HTMLButtonElement>();

  const dispatch = useAppDispatch();
  const { user, authInitialized } = useAppSelector(
    ({ auth }) => ({ user: auth.user, authInitialized: auth.authInitialized }),
    shallowEqual
  );

  const username = useProfile(user?.uid)?.username;
  const emailVerified = useEmailVerified() || !!user?.emailVerified;

  const onLogout = async () => {
    await dispatch(loginActions.logout());
    onClose();
  };

  const menuProps: Partial<MenuProps> = useMemo(
    () => ({
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      transformOrigin: { horizontal: 'left', vertical: 'top' },
    }),
    []
  );

  const onOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchor(undefined);
  }, []);

  const _onCreatePost = useCallback(() => {
    onClose();
    onCreatePost();
  }, [onClose, onCreatePost]);

  const _onLogin = useCallback(() => {
    onClose();
    onLogin();
  }, [onClose, onLogin]);

  const _onSignup = useCallback(() => {
    onClose();
    onSignup();
  }, [onClose, onSignup]);

  const _onLocaleChange = useCallback(() => {
    onClose();
    onLocaleChange();
  }, [onClose, onLocaleChange]);

  const avatar = <BUserAvatar alt={username} userId={user?.uid} />;

  if (!authInitialized || content.__empty) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        className={className}
        aria-controls={DROPDOWN_MENU_ID}
        aria-haspopup="true"
        onClick={onOpen}
        title={username}
      >
        {user ? avatar : !isMd && <MenuRounded className={classes.menuIcon} />}
      </Button>
      <Menu
        id={DROPDOWN_MENU_ID}
        open={!!anchor}
        anchorEl={anchor}
        onClose={onClose}
        getContentAnchorEl={null}
        keepMounted
        {...menuProps}
      >
        {user && [
          ...(!isMd
            ? [
                <MenuItem
                  key="home"
                  component={Link}
                  to={ROUTES.home}
                  onClick={onClose}
                >
                  {content.home}
                </MenuItem>,
              ]
            : []),
          ...(emailVerified
            ? [
                <MenuItem key="createPost" onClick={_onCreatePost}>
                  {content.create_post_cta}
                </MenuItem>,
              ]
            : []),
          <MenuItem
            key="posts"
            component={Link}
            to={ROUTES.myPosts}
            onClick={onClose}
          >
            {content.posts_menu_item}
          </MenuItem>,
          <MenuItem
            key="tacs"
            component={Link}
            to={ROUTES.termsAndConditions}
            onClick={onClose}
          >
            {content.terms_and_conditions}
          </MenuItem>,
          <MenuItem
            key="support"
            onClick={() => {
              window.open('mailto:info@discoverbee.ca');
              onClose();
            }}
          >
            <div>
              Support
              <br />
              <small>info@discoverbee.ca</small>
            </div>
          </MenuItem>,
          // <MenuItem
          //   key="challenges"
          //   component={Link}
          //   to={ROUTES.savedChallenges}
          //   onClick={onClose}
          // >
          //   {content.challenges_menu_item}
          // </MenuItem>,
          <MenuItem
            key="profile"
            component={Link}
            to={ROUTES.myProfileEdit}
            onClick={onClose}
          >
            {content.profile_menu_item}
          </MenuItem>,
          <MenuItem
            key="settings"
            component={Link}
            to={ROUTES.settings}
            onClick={onClose}
          >
            {content.settings_menu_item}
            &nbsp;
            {!emailVerified && (
              <ReportProblemOutlined color="error" fontSize="small" />
            )}
          </MenuItem>,
        ]}
        <MenuItem onClick={_onLocaleChange}>FR/EN</MenuItem>
        {user && (
          <MenuItem onClick={onLogout}>{content.logout_menu_item}</MenuItem>
        )}
        {isBusiness &&
          !user && [
            <MenuItem key="signup" onClick={_onSignup}>
              {content.signup_text}
            </MenuItem>,
            <MenuItem key="login" onClick={_onLogin}>
              {content.login_text}
            </MenuItem>,
          ]}
        <MenuItem
          className="brand"
          component={Link}
          to={ROUTES.getInspired}
          onClick={onClose}
        >
          {content.inspiration_menu_item}
        </MenuItem>
      </Menu>
    </>
  );
};

export default BDropDownMenu;
