import { useEffect, useRef, useState } from 'react';

const useIntersect = ({
  root = null,
  rootMargin = '0px',
  threshold = 0,
}: IntersectionObserverInit = {}): [
  (el: HTMLElement | null) => void,
  boolean
] => {
  const [isIntersection, setIsIntersection] = useState(false);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => setIsIntersection(entry.isIntersecting),
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const { current: currentObserver } = observer;

    if (node) {
      currentObserver.observe(node);
    }
    return () => currentObserver.disconnect();
  }, [node, root, rootMargin, threshold]);

  return [setNode, isIntersection];
};

export default useIntersect;
