import { User } from 'firebase';
import firebase from 'firebase/app';

export type UserObject = Pick<
  User,
  | 'uid'
  | 'email'
  | 'phoneNumber'
  | 'displayName'
  | 'emailVerified'
  | 'providerData'
> & {
  createdAt: string;
  lastLoginAt: string;
};

export const PASSWORD_MIN_LENGTH = 6;

export default class AuthService {
  static instance = new AuthService();

  sendVerificationEmail(user: firebase.User | null = null) {
    return (user ?? firebase.auth().currentUser)!.sendEmailVerification({
      url: window.location.origin!,
    });
  }

  deleteUser() {
    return firebase.auth().currentUser!.delete();
  }

  registerWithEmail(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  userLogin(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  userLogout() {
    return firebase.auth().signOut();
  }

  googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  }

  facebookSignin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  }

  sendPasswordResetEmail(email: string, locale: string) {
    firebase.auth().languageCode = locale;

    return firebase.auth().sendPasswordResetEmail(email, {
      url: window.location.origin!,
    });
  }

  reauthenticateWithPassword(password: string) {
    return firebase
      .auth()
      .currentUser!.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(
          firebase.auth().currentUser!.email!,
          password
        )
      );
  }

  updateEmail(newEmail: string) {
    return firebase.auth().currentUser!.updateEmail(newEmail);
  }

  updatePassword(newPassword: string) {
    return firebase.auth().currentUser!.updatePassword(newPassword);
  }
}
