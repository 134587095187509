import { Color } from '@material-ui/lab';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import AuthService from '../services/AuthService';
import { AppThunk } from '../store';

export interface InfoProps {
  text: string;
  severity?: Color;
}

type StateInfo = InfoProps & {
  timeoutId?: number;
  open: boolean;
};

const showInfo = (info: InfoProps): AppThunk => async (dispatch, getState) => {
  window.clearTimeout(getState().root.info?.timeoutId);

  const timeoutId = window.setTimeout(
    () => dispatch(slice.actions.setInfo({ ...info, open: false })),
    7500
  );

  dispatch(
    slice.actions.setInfo({
      ...info,
      timeoutId,
      open: true,
    })
  );
};

const resendVerificationMail = (successText: string): AppThunk => async (
  dispatch
) => {
  try {
    await AuthService.instance.sendVerificationEmail();

    dispatch(
      showInfo({
        text: `${successText} ${firebase.auth().currentUser!.email}`,
      })
    );
  } catch (e) {
    console.error(e);
  }
};

interface State {
  info?: StateInfo;
}

const INITIAL_STATE: State = {};

const slice = createSlice({
  name: 'root',
  initialState: INITIAL_STATE,
  reducers: {
    setInfo: (state, action: PayloadAction<StateInfo>) => {
      state.info = {
        severity: 'info',
        ...action.payload,
      };
    },
  },
});

export const actions = {
  showInfo,
  resendVerificationMail,
};

export default slice.reducer;
