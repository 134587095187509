import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';

interface State {
  error?: Error;
  hasError: boolean;
}

class BErrorBoundary extends React.Component<{}, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // TODO: refactor to hook when this is supported
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <Box>
          <Alert severity="error" variant="filled">
            {error?.message || 'An error has occurred'}
          </Alert>
        </Box>
      );
    }

    return children;
  }
}

export default BErrorBoundary;
