import { useEffect, useState } from 'react';
import PostsService from '../services/PostsService';
import useAuthState from './useAuthState';

export const useChallengeSaved = (postId: string, subscribe: boolean) => {
  const { user } = useAuthState();
  const [saved, setSaved] = useState(false);
  useEffect(() => {
    if (!subscribe || !user) {
      return;
    }

    const savedSubscription = PostsService.instance.isChallengeSaved$(
      postId,
      (_saved) => setSaved(_saved)
    );

    return () => {
      savedSubscription?.();
    };
  }, [postId, subscribe, user]);
  return saved;
};

export default useChallengeSaved;