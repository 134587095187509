import { Box, Button, Grid } from '@material-ui/core';
import { Geoloc, LatLngBounds } from 'bee';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete/build';
import { useHistory } from 'react-router';
import { compose, withProps } from 'recompose';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import useSearchBounds from '../../hooks/useSearchBounds';
import useSearchCoords from '../../hooks/useSearchCoords';
import useSearchQuery from '../../hooks/useSearchQuery';
import { ROUTES } from '../BRouter';

interface Map {
  bounds?: LatLngBounds;
  coords?: Geoloc;
}

const Map = compose<Map, Map>(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(({ coords, bounds }) => {
  const defaultCenter = { lat: 45.2665, lng: -72.148 };
  const map = useRef<any>();

  useEffect(() => {
    if (bounds) {
      map?.current?.fitBounds(bounds);
    }
  }, [bounds]);

  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={defaultCenter}
      center={coords ? coords : defaultCenter}
      defaultOptions={{
        zoomControl: false,
        panControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        draggable: false,
        scrollwheel: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        draggableCursor: '',
      }}
      ref={map}
    />
  );
});

export interface BLocationSearchProps {
  onClose?: Function;
}

export const BLocationSearch = React.memo(
  ({ onClose }: BLocationSearchProps) => {
    const content = useLocalizedContent('search');
    const searchQuery = useSearchQuery();
    const searchCoords = useSearchCoords();
    const searchBounds = useSearchBounds();

    const history = useHistory();

    const [location, setLocation] = useState('');
    const [coordinates, setCoordinates] = useState<Geoloc | undefined>(
      searchCoords || undefined
    );
    const [bounds, setBounds] = useState<LatLngBounds | undefined>(
      searchBounds || undefined
    );

    const onLocationChange = useCallback(async (d: any) => {
      setLocation(d.label);
      const geocoded = await geocodeByAddress(d.label);
      if (geocoded.length) {
        const firstGeocoded = geocoded[0];
        if (!!firstGeocoded.geometry.bounds) {
          setBounds(firstGeocoded.geometry.bounds.toJSON());
          setCoordinates(undefined);
        } else {
          const latlng = await getLatLng(firstGeocoded);
          setCoordinates(latlng);
          setBounds(firstGeocoded.geometry.viewport.toJSON());
        }
      } else {
        setCoordinates(undefined);
        setBounds(undefined);
      }
    }, []);

    const clear = useCallback(() => {
      if (searchBounds || searchCoords) {
        if (searchQuery) {
          history.push(
            `${ROUTES.getInspired}?q=${encodeURIComponent(searchQuery)}`
          );
        } else {
          history.push(ROUTES.getInspired);
        }
      }
      onClose && onClose();
    }, [history, onClose, searchBounds, searchCoords, searchQuery]);

    const submit = useCallback(() => {
      if (!(coordinates || bounds)) {
        return;
      }

      let basePath = `${ROUTES.getInspired}?q=${searchQuery || ''}`;
      basePath += `&locationLabel=${encodeURIComponent(location)}`;

      if (coordinates) {
        history.push(
          basePath +
            '&coords=' +
            encodeURIComponent(JSON.stringify(coordinates))
        );
        return onClose && onClose();
      } else if (bounds) {
        history.push(
          basePath + '&bounds=' + encodeURIComponent(JSON.stringify(bounds))
        );
        return onClose && onClose();
      }

      return onClose && onClose();
    }, [bounds, coordinates, history, location, onClose, searchQuery]);

    return (
      <>
        <Map bounds={bounds} coords={coordinates} />
        <Box p={1}>
          <GooglePlacesAutocomplete
            debounce={150}
            minLengthAutocomplete={2}
            selectProps={{
              onChange: onLocationChange,
              placeholder: content.search_placeholder,
              menuPosition: 'absolute',
            }}
          />
          <Box pt={1} />
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item xs={6}>
              {!!(searchCoords || searchBounds) && (
                <Button fullWidth onClick={clear}>
                  {content.clear_location}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                color="primary"
                fullWidth
                disabled={!(coordinates || bounds)}
                onClick={submit}
              >
                {content.set_location}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
);

export default BLocationSearch;
