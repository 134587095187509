import { Box, Button } from '@material-ui/core';
import React from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { actions as loginActions } from '../../../slices/login';
import { actions as rootActions } from '../../../slices/root';
import { useAppDispatch } from '../../../store';
import BTextField from '../../inputs/BTextField';

interface Props {
  email: string;
  onEmailChange: (value: string) => void;
  onBack: () => void;
}

const PasswordResetForm: React.FC<Props> = ({
  email,
  onEmailChange,
  onBack,
}) => {
  const dispatch = useAppDispatch();

  const content = useLocalizedContent('login');

  const onForgotPasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      dispatch(loginActions.forgotPasswordSendResetEmail({ email }));

      dispatch(
        rootActions.showInfo({
          text: `${content.reset_password_email_sent_alert_message} ${email}`,
          severity: 'info',
        })
      );

      onBack();
    } else {
      dispatch(
        rootActions.showInfo({
          text: content.forgot_password_no_email_address_error_message,
          severity: 'error',
        })
      );
    }
  };

  return (
    <>
      <Box margin={1}>
        <p>{content.password_reset_instructions}</p>
      </Box>
      <form action="" onSubmit={onForgotPasswordSubmit}>
        <BTextField
          type="email"
          placeholder={content.email_placeholder}
          label={content.email_text}
          onChange={(e) => onEmailChange(e.currentTarget.value)}
          required
        />
        <Box marginY={2}>
          <Button color="primary" type="submit" fullWidth>
            {content.reset_password_button_text}
          </Button>
        </Box>
      </form>
      <Button onClick={onBack} fullWidth>
        {content.cancel_reset_password_reset_email}
      </Button>
    </>
  );
};

export default PasswordResetForm;
