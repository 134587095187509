import { TextField } from '@material-ui/core';
import React, { memo, useCallback, useMemo, useState } from 'react';
import useLocalizedContent from '../../../../hooks/useLocalizedContent';
import PostsService from '../../../../services/PostsService';

interface Props {
  postId: string;
}

const CommentForm: React.FC<Props> = memo(({ postId }) => {
  const content = useLocalizedContent('my_posts');
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const onCommentInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.currentTarget.value);
    },
    []
  );

  const onCommentSubmit = useCallback(
    async (e?: React.FormEvent) => {
      const previousValue = comment;

      e?.preventDefault();

      setLoading(true);
      setComment('');

      try {
        await PostsService.instance.addComment(postId, comment);
      } catch (error) {
        setComment(previousValue);
      } finally {
        setLoading(false);
      }
    },
    [comment, postId]
  );

  const onKeyPress = useCallback(
    async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onCommentSubmit();
      }
    },
    [onCommentSubmit]
  );

  const inputProps = useMemo(
    () => ({
      maxLength: 150,
    }),
    []
  );

  if (content.__empty) {
    return null;
  }

  return (
    <form onSubmit={onCommentSubmit}>
      <TextField
        placeholder={content.write_a_comment}
        onChange={onCommentInputChanged}
        value={comment}
        inputProps={inputProps}
        onKeyPress={onKeyPress}
        disabled={loading}
        multiline
        fullWidth
      />
    </form>
  );
});

export default CommentForm;
