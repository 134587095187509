import { Typography, TypographyProps } from '@material-ui/core';
import { RichText, RichTextProps } from 'prismic-reactjs';
import React from 'react';

interface Props extends TypographyProps<'div'> {
  render: RichTextProps['render'];
}

const BRichText: React.FC<Props> = ({ render, ...rest }) => (
  <Typography component="div" {...rest}>
    <RichText render={render ?? []} />
  </Typography>
);

export default BRichText;
