import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, Post } from 'bee';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PostsService from '../../../services/PostsService';
import BVideoPlayer from '../../utils/BVideoPlayer';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  post: Post & Id;
}

enum VideoPlatforms {
  Firebase,
  Mux,
}

const MUX_SCHEMA = 'mux://';

const useStyles = makeStyles((theme) => ({
  loading: {
    minHeight: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background96,
  },
}));

const BPostVideoPlayer: React.FC<Props> = ({ post, ...rest }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(post.mediaStatus === 'ready');
  const [overrideSrc, setOverrideSrc] = useState<string>();
  const realUrl = overrideSrc ?? post.mediaUrl;

  const platform = realUrl?.startsWith(MUX_SCHEMA)
    ? VideoPlatforms.Mux
    : VideoPlatforms.Firebase;

  const postId = post.id;
  const postMediaStatus = post.mediaStatus;
  const playbackId =
    platform === VideoPlatforms.Mux
      ? realUrl!.replace(MUX_SCHEMA, '')
      : undefined;

  useEffect(() => {
    if (postMediaStatus !== 'processing') {
      return;
    }

    const unsubscribe = PostsService.instance.mediaStatus$(postId, (result) => {
      if (!result || (result.mediaStatus !== 'processing' && result.mediaUrl)) {
        window.setTimeout(() => {
          setLoaded(true);

          if (result?.mediaUrl) {
            setOverrideSrc(result.mediaUrl);
          }

          unsubscribe();
        }, 2000);
      }
    });

    return unsubscribe;
  }, [postId, postMediaStatus, platform]);

  if (!loaded) {
    return (
      <div className={clsx(rest.className, classes.loading)} {...rest}>
        <CircularProgress />
      </div>
    );
  }

  switch (platform) {
    case VideoPlatforms.Firebase:
      return (
        <div {...rest}>
          <video height="100%" width="100%" controls>
            <source src={realUrl!} />
          </video>
        </div>
      );
    case VideoPlatforms.Mux:
      if (!playbackId) {
        return null;
      }

      return (
        <BVideoPlayer
          poster={`https://image.mux.com/${playbackId}/thumbnail.png?fit_mode=pad`}
          src={`https://stream.mux.com/${playbackId}.m3u8`}
        />
      );
  }

  return null;
};

export default BPostVideoPlayer;
