import { useMemo } from 'react';
import useQueryParams from './useQueryParams';

export const useSearchCoords = () => {
  const params = useQueryParams();
  const value = params.get('coords');
  return useMemo(() => {
    if (!value) {
      return value;
    }
    return JSON.parse(value);
  }, [value]);
};

export default useSearchCoords;