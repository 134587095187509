import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, Post } from 'bee';
import React, { memo } from 'react';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import BPostCardListItem from './BPostCard/BPostCardListItem';

interface Props {
  className?: string;
  posts: (Post & Id)[];
  nextPage: () => Promise<void>;
  shouldLoad: boolean;
}

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'block',
    margin: `${theme.spacing(4)}px auto`,
  },
}));

const BPostCardInfiniteScroller: React.FC<Props> = memo(
  ({ className, posts, nextPage, shouldLoad }) => {
    const classes = useStyles();
    const content = useLocalizedContent('my_posts');
    const [ref, loading] = useInfiniteScroll(nextPage, shouldLoad);

    return !!posts && posts.length > 0 ? (
      <div className={className}>
        {posts.map((post) => (
          <BPostCardListItem
            key={`${post.id}${post.mediaUrl ?? ''}`}
            post={post}
          />
        ))}
        {loading && <CircularProgress className={classes.loader} />}
        <div ref={ref} />
      </div>
    ) : (
      <>{content.noPostsMessage}</>
    );
  }
);

export default BPostCardInfiniteScroller;
