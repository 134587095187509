import { Cause, Id } from 'bee';
import { useEffect, useState } from 'react';
import CausesService from '../services/CausesService';
import useLocale from './useLocale';

export const useCause = (id: string) => {
  const [cause, setCause] = useState<(Cause & Id)>();
  const locale = useLocale().split('-')[0];

  useEffect(() => {
    if(!id){
      return;
    }
    (async () => {
      const _cause = await CausesService.instance.getTranslation(id, locale);
      setCause(_cause);
    })();
  }, [setCause, locale, id]);

  return cause;
};

export default useCause;