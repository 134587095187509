import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as postActions } from '../slices/posts';

const { setShowEditPost } = postActions;

type actionType = Parameters<typeof setShowEditPost>[0];

export default () => {
  const dispatch = useDispatch();
  return useCallback((params: actionType) => {
    dispatch(postActions.setShowEditPost(params));
  }, [dispatch]);
}