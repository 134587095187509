import { SearchOptions } from '@algolia/client-search';
import { RequestOptions } from '@algolia/transporter';
import algoliasearch from 'algoliasearch/lite';
import { Post, SearchProfile } from 'bee';

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPID!,
  process.env.REACT_APP_ALGOLIA_SEARCHKEY!
);

function createTypedAdapter<T extends object>(indexName: string) {
  const index = client.initIndex(indexName);

  return {
    search: (query: string, options?: RequestOptions & SearchOptions) =>
      index.search<T>(query, options),
  };
}

export default {
  posts: createTypedAdapter<Post>('posts'),
  profiles: createTypedAdapter<SearchProfile>('profiles'),
};
