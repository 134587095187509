import { createMuiTheme } from '@material-ui/core';

const spacing = 8;

// this is a base theme to be used for overrides further down
export default () =>
  createMuiTheme({
    gradients: {
      primary: 'linear-gradient(270deg,#52c8c0,#46aea7)',
      action: 'linear-gradient(272deg, #272d48, #533fb0 29%, #68f2ca)',
      actionLarge:
        'linear-gradient(-45deg, rgba(39, 45, 72, 0.1) 0%, rgba(83, 63, 176, 0.1) 29%, rgba(104, 242, 202, 0.1) 100%)',
      button: 'linear-gradient(to left, #52c8c0, #46aea7)',
      brand: 'linear-gradient(to right, #2c2f54 0%, #533fb0 100%)',
      brandCircle:
        'radial-gradient(circle at bottom right, #272d48, #a18fb4 29%, #68f2ca)',
      error: 'linear-gradient(270deg, #f32e20 0%, #f55347 100%)',
    },
    typography: {
      fontFamily: '"Maven Pro", sans-serif',
      fontFamilyTitle: 'Quicksand, sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
    },
    palette: {
      divider: '#979797',
      divider96: '#f6f6f6',
      like: '#f05229',
      google: '#db4437',
      superLike: '#006bff',
      facebook: '#3b5998',
      brand: {
        main: '#6d60fa',
        light: '#a18fb4',
        dark: '#2c2f54',
        contrastText: '#ffffff',
      },
      primary: {
        light: '#52c8c0',
        main: '#46aea7',
        dark: '#008080',
        contrastText: '#ffffff',
      },
      text: {
        secondary: '#4a4a4a',
      },
      background96: '#efeff4',
    },
    spacing,
    shape: {
      borderRadius: spacing * 2.5,
    },
  });
