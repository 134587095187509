import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ContentService from '../services/ContentService';
import { AppThunkApiConfig } from '../store';

export type SupportedLocale = 'en-ca' | 'fr-ca';

/**
 * Note that code in BNavBar only supports two locales.
 * If you need to add more, we need to look at our system more
 * broadly.
 * @type {string[]}
 */
export const supportedLocales: SupportedLocale[] = ['en-ca', 'fr-ca'];

export const LANGUAGE_STORAGE_KEY = 'com.beeappmobile.lang';

export const getKey = (locale: SupportedLocale, type: string) =>
  `${locale}:${type}`;

/**
 * The existing code assumed that all visitors would have browsers that match the locale
 * and didn't offer a fallback. This code will select locale by the browser language
 * and if no matches exist, will fall back to the first existing french locale.
 * @returns {string}
 */
const getDefaultLanguage = () => {
  try {
    const stored = window.localStorage.getItem(LANGUAGE_STORAGE_KEY);

    if (stored) {
      return stored as SupportedLocale;
    }
  } catch (e) {}

  let _language = window.navigator.language;

  // @ts-ignore
  _language = _language || window.navigator.userLanguage;

  const browserLocale = _language?.toLocaleLowerCase();
  const language = browserLocale.split('-')[0]; // should work for "en" as well
  const existingLocale = supportedLocales.find((locale) =>
    locale.startsWith(language)
  );
  if (existingLocale) {
    return existingLocale;
  }
  return (
    supportedLocales.find((locale) => locale.startsWith('fr')) ??
    ('en_CA' as SupportedLocale)
  );
};

interface ContentResponse {
  type: string;
  locale: SupportedLocale;
  data: ContentRecord;
}

export const loadContent = createAsyncThunk<
  ContentResponse,
  { type: string },
  AppThunkApiConfig
>('content/loadContent', async ({ type }, { getState }) => {
  const { content } = getState();
  const locale = content.locale;

  const data = await ContentService.getContent(type, locale);

  const isEmpty = !Object.keys(data).length;

  return {
    type,
    locale,
    data: {
      ...data,
      __empty: isEmpty,
    },
  };
});

export type ContentRecord = {
  [key: string]: any;
  __empty: boolean;
};

interface State {
  locale: SupportedLocale;
  loading: Record<string, boolean>;
  data: Record<string, ContentRecord>;
}

const INITIAL_STATE: State = {
  locale: getDefaultLanguage(),
  loading: {},
  data: {},
};

const slice = createSlice({
  name: 'content',
  initialState: INITIAL_STATE,
  reducers: {
    localeChanged: (state, action: PayloadAction<SupportedLocale>) => {
      state.locale = action.payload;

      try {
        window.localStorage.setItem(LANGUAGE_STORAGE_KEY, action.payload);
      } catch (e) {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadContent.pending, (state, action) => {
      const { type } = action.meta.arg;
      state.loading[getKey(state.locale, type)] = true;
    });

    builder.addCase(loadContent.fulfilled, (state, action) => {
      const { locale, type, data } = action.payload;

      state.data[getKey(locale, type)] = data;
      state.loading[getKey(state.locale, type)] = false;
    });
  },
});

export const actions = {
  ...slice.actions,
  loadContent,
};

export default slice.reducer;
