import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppPreferences, Id, Profile } from 'bee';
import firebase from 'firebase/app';
import ProfilesService from '../services/ProfilesService';
import { AppThunk, AppThunkApiConfig } from '../store';
import { bAnalytics } from '../utils/analytics';

interface State {
  appPreferences: AppPreferences;
  profileImageRefresh: number;
  profiles: {
    [key: string]: Profile & Id;
  };
  profilesLoading: {
    [key: string]: boolean;
  };
}

const INITIAL_STATE: State = {
  appPreferences: {
    notificationsChecked: 0,
  },
  profileImageRefresh: 0,
  profiles: {},
  profilesLoading: {},
};

const updateUserProfile = (payload: Profile): AppThunk => async () => {
  // remove undefined fields
  Object.keys(payload).forEach((key) => (payload as any)[key as any] === undefined && delete (payload as any)[key as any]);
  await ProfilesService.instance.update(
    firebase.auth().currentUser!.uid,
    payload
  );

  bAnalytics.updateProfile(payload);
};

const getSingleProfile = createAsyncThunk<
  (Profile & Id) | undefined,
  string,
  AppThunkApiConfig
>('profiles/getSingleProfile', async (profileId) =>
  ProfilesService.instance.get(profileId)
);

const slice = createSlice({
  name: 'profiles',
  initialState: INITIAL_STATE,
  reducers: {
    setAppPreferences: (
      state,
      action: PayloadAction<AppPreferences | undefined>
    ) => {
      state.appPreferences.notificationsChecked =
        action.payload?.notificationsChecked ?? 0;
    },
    setProfile: (
      state,
      action: PayloadAction<{ id: string; profile: (Profile & Id) | null }>
    ) => {
      if (action.payload.profile) {
        state.profiles[action.payload.id] = action.payload.profile;
      }

      state.profilesLoading[action.payload.id] = false;
    },
    refreshProfileImage: (state) => {
      state.profileImageRefresh += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleProfile.pending, (state, action) => {
      state.profilesLoading[action.meta.arg] = true;
    });
    builder.addCase(getSingleProfile.fulfilled, (state, action) => {
      if (action.payload) {
        state.profiles[action.payload.id] = action.payload;
      }

      state.profilesLoading[action.meta.arg] = false;
    });
  },
});

export const actions = {
  ...slice.actions,
  updateUserProfile,
  getSingleProfile,
};

export default slice.reducer;
