import {
  fade,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, Notification } from 'bee';
import clsx from 'clsx';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useI18nFormat from '../../../hooks/useI18nFormat';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useProfile from '../../../hooks/useProfile';
import { interpolate } from '../../../utils/format';
import BUserAvatar from '../../utils/BUserAvatar';

interface Props {
  notification: Notification & Id;
  isFresh: boolean;
  onClick?: () => void;
}

function relativizeLink(input: string) {
  return `/${input
    .replace(/http(s?):\/\//, '')
    .replace(/bee:\/\//, '')
    .split('/')
    .slice(1)
    .join('/')}`;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    minWidth: theme.spacing(6),
  },
  isFresh: {
    backgroundColor: fade(theme.palette.primary.light, 0.25),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.light, 0.4),
    },
  },
}));

const NotificationListItem: React.FC<Props> = memo(
  forwardRef<HTMLDivElement, Props>(
    ({ notification, isFresh, onClick }, ref) => {
      const classes = useStyles();
      const content = useLocalizedContent('nav');
      const format = useI18nFormat();
      const history = useHistory();

      const profile = useProfile(notification.meta?.userId);

      const text = useMemo(() => {
        switch (notification.type) {
          case 'NewComment':
            return (
              <>
                <strong>{profile?.username} </strong>
                {interpolate(content.new_comment, {
                  comment: notification.content,
                })}
              </>
            );
          case 'NewLike':
            return (
              <>
                <strong>{profile?.username} </strong>
                {content.new_like}
              </>
            );
          case 'NewSuperLike':
            return (
              <>
                <strong>{profile?.username} </strong>
                {content.new_super_like}
              </>
            );
          default:
            return '';
        }
      }, [
        content.new_comment,
        content.new_like,
        content.new_super_like,
        notification.content,
        notification.type,
        profile,
      ]);

      const _onClick = useCallback(() => {
        if (notification.link) {
          console.log(relativizeLink(notification.link));
          history.push(relativizeLink(notification.link));
        }
        onClick?.();
      }, [history, notification.link, onClick]);

      return (
        <ListItem
          ref={ref}
          className={clsx(classes.root, isFresh && classes.isFresh)}
          button
          onClick={_onClick}
          dense
        >
          <ListItemAvatar className={classes.avatar}>
            <BUserAvatar userId={notification.meta?.userId} />
          </ListItemAvatar>
          <ListItemText
            primary={text}
            secondary={format.formatDistance(notification.timestamp)}
          />
        </ListItem>
      );
    }
  )
);

export default NotificationListItem;
