import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
  },
  image: {
    display: 'block',
  },
  placeholder: {
    height: 350,
    background: theme.gradients.actionLarge,
  },
  hidden: {
    visibility: 'hidden',
  },
}));

const BImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({
  ...rest
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading && (
        <div className={clsx(classes.content, classes.placeholder)} />
      )}
      <img
        alt=""
        {...rest}
        className={clsx(
          classes.content,
          classes.image,
          isLoading && classes.hidden
        )}
        onLoad={() => setIsLoading(false)}
      />
    </>
  );
};

export default BImage;
