import {
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props extends OutlinedInputProps {
  label: string;
  error?: boolean;
}

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    display: 'block',
    paddingLeft: theme.spacing(1),
  },
}));

const BTextField: React.FC<Props> = ({ label, error, className, ...rest }) => {
  const classes = useStyles();

  return (
    <InputLabel className={className} error={error} disableAnimation>
      <span className={classes.label}>{label}</span>
      <OutlinedInput className={classes.input} error={error} {...rest} />
    </InputLabel>
  );
};

export default BTextField;
