import { makeStyles } from '@material-ui/core/styles';
import { Stars } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { actions as postActions } from '../../../slices/posts';
import { useAppDispatch, useAppSelector } from '../../../store';
import BDialog from '../BDialog';
import BNewPostForm from './BNewPostForm';
import useUser from '../../../hooks/useUser';
import ProfilesService from '../../../services/ProfilesService';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 380,
  },
  icon: {
    fontSize: 30,
    marginBottom: -6,
    marginRight: 10,
  },
  paper: {
    overflow: 'visible',
  },
});

const BNewPostDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const content = useLocalizedContent('nav');
  const createPostContent = useLocalizedContent('create_post');
  const userId = useUser()?.uid;
  const history = useHistory();

  const fetchProfile = useCallback(async (params: (string | undefined)[]) => {
    const [id] = params;
    if (!id) {
      return;
    }
    const profile = await ProfilesService.instance.get(id);
    return profile;
  }, []);

  const { data: profileData, isLoading: profileLoading } = useSWR(
    [userId, 'profiles'],
    fetchProfile
  );

  const showCreatePost = useAppSelector(
    ({ posts: { showCreatePost } }) => showCreatePost
  );

  useEffect(() => {
    const canPost =
      !!profileData?.subscribedUntil &&
      profileData.subscribedUntil > Date.now() / 1000;
    if (showCreatePost && !profileLoading && !canPost) {
      console.log('@@ gotta subscribe');
      dispatch(postActions.setShowCreatePost(false));
      history.push('/landing/discover-bee');
    }
  }, [profileLoading, profileData, showCreatePost, history, dispatch]);

  const onClose = useCallback(() => {
    dispatch(postActions.setShowCreatePost(false));
  }, [dispatch]);

  const isChallenge = useAppSelector(
    ({ posts: { isChallenge } }) => !!isChallenge
  );

  const { editPost } = useAppSelector(({ posts }) => posts);

  const isChallengeAccepted = useAppSelector(
    ({ posts: { rootPost } }) => !!rootPost
  );

  return (
    <BDialog
      classes={{
        paperWidthXs: classes.root,
        paper: classes.paper,
      }}
      open={!!showCreatePost}
      title={
        isChallenge || isChallengeAccepted ? (
          <>
            <Stars className={classes.icon} />
            {isChallengeAccepted
              ? createPostContent.accept_the_challenge
              : createPostContent.submit_a_challenge}
          </>
        ) : editPost ? (
          createPostContent.edit_post
        ) : (
          content.create_post_cta
        )
      }
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      disableContentWrapper
      disableRestoreFocus
    >
      <BNewPostForm onPostSuccess={onClose} />
    </BDialog>
  );
};

export default BNewPostDialog;
