import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Id, SearchProfile } from 'bee';
import React, { memo } from 'react';
import BProfileIcon from './BProfileIcon';
import BProfileLink from './BProfileLink';
import BUserAvatar from './BUserAvatar';

interface Props {
  profile: SearchProfile & Id;
}

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  headerContent: {
    overflow: 'hidden',
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0, 1.5),
    '&:last-child': {
      paddingBottom: theme.spacing(1.5),
    },
  },
}));

const BMiniProfile: React.FC<Props> = memo(({ profile }) => {
  const classes = useStyles();

  return (
    <BProfileLink className={classes.root} userId={profile.id}>
      <Card>
        <CardHeader
          classes={{
            content: classes.headerContent,
          }}
          avatar={<BUserAvatar userId={profile.id} />}
          title={profile.username}
          titleTypographyProps={{
            noWrap: true,
          }}
          action={<BProfileIcon profile={profile} />}
        />
        <CardContent classes={{ root: classes.content }}>
          <Typography variant="caption" color="textSecondary" noWrap>
            {profile.aboutMe || ' '}
          </Typography>
        </CardContent>
      </Card>
    </BProfileLink>
  );
});

export default BMiniProfile;
