import React, { useCallback, useState } from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { actions as authActions } from '../../../slices/auth';
import { useAppDispatch, useAppSelector } from '../../../store';
import BDialog from '../BDialog';
import LoginForm from './LoginForm';
import PasswordResetForm from './PasswordResetForm';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

const BAuthModal = () => {
  const dispatch = useAppDispatch();
  const loginContent = useLocalizedContent('login');
  const registrationContent = useLocalizedContent('registration');

  const [email, setEmail] = useState('');
  const [forgotPasswordRequest, setForgotPasswordRequest] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);

  const authModal = useAppSelector(({ auth: { authModal } }) => authModal);

  const onClose = useCallback(() => dispatch(authActions.closeModal()), [
    dispatch,
  ]);

  if (loginContent.__empty || registrationContent.__empty) {
    return null;
  }

  return (
    <>
      <BDialog
        id="loginModal"
        title={
          forgotPasswordRequest
            ? loginContent.forgot_password_modal_title
            : authModal === 'login'
            ? loginContent.login_title
            : registrationContent.form_title
        }
        open={!!authModal}
        onClose={onClose}
      >
        {forgotPasswordRequest ? (
          <PasswordResetForm
            email={email}
            onEmailChange={setEmail}
            onBack={() => setForgotPasswordRequest(false)}
          />
        ) : (
          <LoginForm
            email={email}
            onEmailChange={setEmail}
            onPasswordForgot={() => setForgotPasswordRequest(true)}
            onTermsOfUse={() => setShowTermsOfUse(true)}
          />
        )}
      </BDialog>
      <TermsAndConditionsDialog
        open={showTermsOfUse}
        onClose={() => setShowTermsOfUse(false)}
      />
    </>
  );
};

export default BAuthModal;
