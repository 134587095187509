import { makeStyles } from '@material-ui/core/styles';
import { User } from 'firebase';
import firebase from 'firebase/app';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useLocale from '../hooks/useLocale';
import useUser from '../hooks/useUser';
import { UserObject } from '../services/AuthService';
import NotificationsService from '../services/NotificationsService';
import ProfilesService from '../services/ProfilesService';
import { actions as authActions } from '../slices/auth';
import { actions as notificationActions } from '../slices/notifications';
import { actions as profileActions } from '../slices/profiles';
import { bAnalytics } from '../utils/analytics';
import { Environments, getEnv } from '../utils/environment';
import { ROUTES } from './BRouter';
import BErrorBoundary from './utils/BErrorBoundary';
import BSnackbar from './utils/BSnackbar';

if (getEnv() !== Environments.Development) {
  console.warn('Welcome to Bee.');
}

/**
 * Private hook to get the query params as an object.
 * @returns {{}}
 */
const useQuery = () => {
  const location = useLocation();
  const _query = new URLSearchParams(location.search);
  let query: Record<string, string> = {};

  for (const [key, value] of _query.entries()) {
    query[key] = value;
  }

  return query;
};

/**
 * Private hook to track page changes in analytics.
 */
const useTrackPages = () => {
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    bAnalytics.page();
  }, [location.pathname, query]);
};

/**
 * Identify a user after the log in, or
 * de-identify them when they log out.
 * @param user
 */
const identifyUser = (user: User | null) => {
  if (!user) {
    bAnalytics.reset();
  } else {
    bAnalytics.identifyUser();
  }
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    a: {
      color: theme.palette.primary.main,
    },
  },
}));

const useForceOnboarding = () => {
  const user = useUser();
  const uid = user?.uid;
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAndOnboard = useCallback(
    async (uid: string) => {
      const { payload: profile } = (await dispatch(
        profileActions.getSingleProfile(uid)
      )) as any;
      if (!profile?.username || profile?.username.startsWith('user_')) {
        history.push(ROUTES.onboarding);
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (uid) {
      fetchAndOnboard(uid);
    }
  }, [uid, dispatch, fetchAndOnboard]);
};

/**
 * Main application wrapper.
 * @param props
 * @returns {*}
 * @constructor
 */
const App: React.FC = ({ children }) => {
  useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useLocale();
  const user = useUser();
  const [profileLoaded, setProfileLoaded] = useState(false);

  useTrackPages();

  useForceOnboarding();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        dispatch(
          authActions.setUser(
            (user?.toJSON() as UserObject | undefined) ?? null
          )
        );

        identifyUser(user);
      }),
    [dispatch, history]
  );

  useEffect(() => {
    window.firebase = firebase;
    firebase.auth().languageCode = locale.replace('-', '_');

    if (profileLoaded && user) {
      ProfilesService.instance.setLocale(user.uid, locale);
    }
  }, [profileLoaded, locale, user]);

  useEffect(() => {
    if (!user) {
      dispatch(profileActions.setAppPreferences(undefined));
      dispatch(notificationActions.setNotifications([]));
      return;
    }

    const profileUnsubscribe = ProfilesService.instance
      .doc(user.uid)
      .onSnapshot((sn) => {
        setProfileLoaded(true);

        dispatch(
          profileActions.setProfile({
            id: user.uid,
            profile: sn.data() ?? null,
          })
        );
      });

    const appPreferencesUnsubscribe = ProfilesService.instance
      .appPreferencesDoc(user.uid)
      .onSnapshot((sn) =>
        dispatch(profileActions.setAppPreferences(sn.data()))
      );

    const notificationsUnsubscribe = NotificationsService.instance.incomingNotifications$(
      { userId: user.uid },
      (sn) => dispatch(notificationActions.setNotifications(sn))
    );

    return () => {
      profileUnsubscribe();
      appPreferencesUnsubscribe();
      notificationsUnsubscribe();
    };
  }, [dispatch, user]);

  return (
    <BErrorBoundary>
      <Suspense fallback={<div />}>
        {children}
        <BSnackbar />
      </Suspense>
    </BErrorBoundary>
  );
};

export default App;
