import firebase from 'firebase/app';
import toPairs from 'lodash/toPairs';

export const unwrapFirebaseError = (
  e: Error | firebase.auth.AuthError,
  messages: Record<string, any>
) =>
  messages[(e as firebase.auth.AuthError).code?.replace('/', '_')] ??
  messages['bee_generic-error'];

export const interpolate = (
  content: string | undefined,
  params: Record<string, string | undefined>
) => {
  return toPairs(params).reduce(
    (acc, [k, v]) => (acc ?? '').replace(new RegExp(`{{${k}}}`, 'g'), v ?? ''),
    content
  );
};
