import {
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.background96,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

interface Props extends DialogProps {
  actions?: React.ReactNode;
  disableContentWrapper?: boolean;
  ContentProps?: DialogContentProps;
}

const BDialog: React.FC<Props> = ({
  id,
  title,
  actions,
  onClose,
  children,
  ContentProps,
  disableContentWrapper = false,
  ...rest
}) => {
  const _onClose = useCallback(() => onClose?.({}, 'escapeKeyDown'), [onClose]);

  const titleId = `${id}-title`;

  return (
    <Dialog
      onClose={_onClose}
      aria-labelledby={titleId}
      maxWidth="xs"
      fullWidth
      scroll="body"
      {...rest}
    >
      <DialogTitle id={titleId} onClose={_onClose}>
        {title}
      </DialogTitle>
      {disableContentWrapper ? (
        children
      ) : (
        <DialogContent {...ContentProps}>{children}</DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BDialog;
