import Prismic from 'prismic-javascript';
import { SupportedLocale } from '../slices/content';

const API_ENDPOINT = process.env.REACT_APP_PRISMIC_ENDPOINT!;

export const prismicClient = Prismic.client(API_ENDPOINT);

interface GetContentOptions {
  multiple?: boolean;
  additionalPredicates?: string[];
}

const getContent = async (
  type: string,
  locale: SupportedLocale,
  { multiple = false, additionalPredicates = [] }: GetContentOptions = {}
): Promise<Record<string, any>> => {
  const response = await prismicClient.query(
    [Prismic.Predicates.at('document.type', type), ...additionalPredicates],
    {
      lang: locale,
    }
  );

  if (multiple) return response.results;

  return response.results[0].data;
};

export default {
  getContent,
};
